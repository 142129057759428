<template>
  <div class="offer-view">
    <offer-view-base-header
      :offer-recipient="customerOrganizationName"
      :last-saved="lastSaveDate"
      @go-back="goBackWithModalConfirmation"
    >
      <template v-if="showPageActionButtons" #right-content>
        <div class="offer-view__header-buttons">
          <tertiary-button
            variant="inverted"
            :disabled="saveButtonDisabled"
            :is-submitting="isSavingOffer"
            @click="saveOffer"
          >
            {{ getButtonText("saveDraftButton") }}
          </tertiary-button>
          <primary-button variant="inverted" @click="sendOffer">
            {{ getButtonText("sendOfferButton") }}
          </primary-button>
        </div>
      </template>
    </offer-view-base-header>
    <div class="offer-view__feedback-messages">
      <div
        v-for="feedbackMessage in feedbackMessages"
        :key="feedbackMessage.id"
        class="offer-view__feedback-message"
      >
        <feedback-overlay
          :feedback-type="feedbackMessage.type"
          :message-title="feedbackMessage.messageTitle"
          :message-info="feedbackMessage.messageInfo"
          @close="removeFeedbackMessage(feedbackMessage)"
        />
      </div>
    </div>
    <div v-if="pageIsReady" class="offer-view__container">
      <div class="offer-view__title-wrapper">
        <h1 class="offer-view__title">{{ pageTitle }}</h1>
        <button class="offer-view__new-tab-button" @click="handleOnNewTabClick">
          <img :src="`/images/new_tab.svg`" alt="new-tab" />
          Hvordan lage tilbud?
        </button>
      </div>
      <div v-if="!pageIsReadOnly" class="offer-view__change-offer-name-button">
        <secondary-button
          :icon-src="editOfferNameButtonIcon"
          :text="editOfferNameButtonText"
          @click="openEditOfferNameModal"
        />
      </div>
      <div class="offer-view__agreement-partners">
        <agreement-partners
          :tender="tender"
          :supplier-org-number="supplierOrgNumber"
          :collapsible="!pageIsReadOnly"
        />
      </div>
      <div class="offer-view__activities-and-cost">
        <activities-costs-section
          :tender="updatedTender"
          :service-offer="serviceOffer"
          :page-cms="pageCms"
          :offer="localOffer"
          :frequency-options="frequencyOptions"
          :service-activities-cms="serviceActivitiesCms"
          :read-only="pageIsReadOnly"
          :supplier-info="supplierInfo"
          :is-saving="isSavingOffer"
          @new-offer="updateAndSaveLocalOffer"
        />
      </div>
      <div class="offer-view__work-description">
        <description-of-work
          :page-cms="pageCms"
          :offer="localOffer"
          :read-only="pageIsReadOnly"
          @new-offer="updateAndSaveLocalOffer"
        />
      </div>
      <div class="offer-view__terms">
        <terms
          :page-cms="pageCms"
          :tender="updatedTender"
          :offer="localOffer"
          :read-only="pageIsReadOnly"
          :minimum-days-to-start-date="minimumDaysToStartDate"
          :frame-agreement="frameAgreement"
          @new-offer="updateAndSaveLocalOffer"
        />
      </div>
      <div class="offer-view__product-description-uploads">
        <upload-product-description
          :page-cms="pageCms"
          :offer="localOffer"
          :tender="updatedTender"
          :read-only="pageIsReadOnly"
          @new-offer="updateAndSaveLocalOffer"
        />
      </div>
      <go-back-from-offer-modal
        v-model="showGoBackFromOfferModal"
        :go-back-from-offer-modal-cms="goBackFromOfferModalCms"
        @discard-changes="goBack"
        @save-changes="saveOffer"
      />
      <change-name-on-offer-modal
        v-model="showEditOfferNameModal"
        :title="editOfferNameButtonText"
        :change-name-form-cms="changeNameFormCms"
        :initial-offer-name="offerName"
        :new-service-offer-confirmed="serviceOfferConfirmed"
        @new-offer-name="updateOfferName"
        @back="goBack"
      />
      <late-offer-modal
        v-if="showLateOfferModal"
        v-model="showLateOfferModal"
        :tender-id="tender.tenderId"
        @ok="handleLateOfferOk"
      />
      <send-offer-modal
        v-if="serviceOffer"
        v-model="showSendOfferModal"
        :tender="updatedTender"
        :service-offer="serviceOffer"
        :supplier-id="supplierId"
        @offer-sent="handleOfferSent"
      />
    </div>
    <div v-else class="offer-view__loader">
      <b-spinner label="Large Spinner" type="grow" />
    </div>
  </div>
</template>

<script>
import Terms from "@/views/TenderOffer/EditOfferView/Terms/Terms.vue";
import LateOfferModal from "@/components/LateOfferModal/LateOfferModal.vue";
import OfferViewBaseHeader from "@/components/OfferViewBaseHeader/OfferViewBaseHeader.vue";
import TertiaryButton from "@/components/Buttons/TertiaryButton.vue";
import PrimaryButton from "@/components/Buttons/PrimaryButton.vue";
import SecondaryButton from "@/components/Buttons/SecondaryButton.vue";
import AgreementPartners from "@/components/AgreementPartners/AgreementPartners.vue";
import cmsService from "@/services/cmsService";
import UploadProductDescription from "@/views/TenderOffer/EditOfferView/UploadProductDescription/UploadProductDescription.vue";
import DescriptionOfWork from "@/components/SupplierOfferWorkDescription/DescriptionOfWork.vue";
import ActivitiesCostsSection from "@/components/ActivitiesCosts/ActivitiesCostsSection.vue";
import GoBackFromOfferModal from "@/components/SupplierOffersWrapper/SupplierOffersModals/GoBackFromOfferModal.vue";
import ChangeNameOnOfferModal from "@/components/ChangeNameOnOfferModal/ChangeNameOnOfferModal.vue";
import {
  getCmsItem,
  getCmsItemText,
  getIconUrl,
} from "@/utilities/cmsUtilities";
import { cloneDeep, isEqual } from "lodash";
import supplierCommunicationService from "@/services/supplierCommunicationService";
import dayjs from "dayjs";
import { uuidv4 } from "@/utilities/uuid";
import {
  FeedbackType,
  OfferStatus,
  TenderState,
  MessageDomain,
  MessageType,
} from "@/custom-types/types";
import { RequestState } from "@/custom-types/GeneralTypes";
import FeedbackOverlay from "@/components/FeedbackOverlay/FeedbackOverlay.vue";
import {
  extractLocalOfferFromServiceOffer,
  getPriceRequestForGivenServiceOffer,
  getServiceOfferFromTender,
  getServiceCategory,
} from "@/utilities/tenderUtilities";
import SendOfferModal from "@/components/SendOfferModal/SendOfferModal.vue";
import { RouteNames } from "@/router/routes";
import useOffer from "@/hooks/useOffer";
import { convertFileToBase64 } from "@/utilities/fileUtilities";
import { useTenderApi } from "@/services/api/useTenderApi";
import { addBusinessDays } from "@/utilities/dateUtils";
import utc from "dayjs/plugin/utc";
//import { ActivityFrequency } from "@/custom-types/types";

dayjs.extend(utc);
const {
  getTender,
  getActivityFormFrequencyOptions,
  updateSupplierTenderOffer,
  createSupplierTenderOffer,
  getFrameAgreement,
} = useTenderApi();

const {
  getFixedCostActivitiesDTOFromLocalOffer,
  getVariableCostActivitiesDTOFromLocalOffer,
  getDescriptionsDTOFromLocalOffer,
  getRemovedAttachmentsDTO,
  getAddedAttachmentsDTO,
  getRemovedActivityIds,
} = useOffer();

const CmsFeedbackLabels = {
  saved: "saved",
  saveError: "saveError",
  saveRequirementsError: "saveRequirementsError",
  sendHasUnsavedChanges: "sendHasUnsavedChanges",
  sendRequirementsError: "sendRequirementsError",
  invalidWantedStartDateError: "invalidWantedStartDateError",
};

export default {
  components: {
    SendOfferModal,
    FeedbackOverlay,
    DescriptionOfWork,
    UploadProductDescription,
    AgreementPartners,
    PrimaryButton,
    TertiaryButton,
    OfferViewBaseHeader,
    ActivitiesCostsSection,
    GoBackFromOfferModal,
    ChangeNameOnOfferModal,
    SecondaryButton,
    LateOfferModal,
    Terms,
  },

  props: {
    tender: {
      type: Object,
      required: true,
    },
    supplierInfo: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      pageIsReady: false,
      pageCms: null,
      showGoBackFromOfferModal: false,
      showEditOfferNameModal: false,
      serviceOfferConfirmed: false,
      showSendOfferModal: false,
      localOffer: this.getDefaultLocalOffer(),
      frequencyOptions: null,
      isSavingOffer: false,
      feedbackMessages: [],
      serviceActivitiesCms: null,
      conversations: null,
      queueSave: false,
      minimumDaysToStartDate: 1,
      offerName: "",
      updatedTender: {},
      showLateOfferModal: false,
      tenderCache: new Map(),
      pendingFetches: new Map(),
      lastFetchTimestamp: new Map(),
      CACHE_TTL: 5 * 60 * 1000,
      frameAgreement: null,
      isLoadingFrameAgreement: false,
    };
  },
  computed: {
    serviceTypesCms() {
      return this.$store.state.cms.serviceTypes;
    },

    pageIsReadOnly() {
      if (!this.serviceOffer) {
        return false;
      }
      if (
        this.tender.tenderState === TenderState.Accepted ||
        this.tender.tenderState === TenderState.Completed
      ) {
        return true;
      }
      return this.serviceOffer.offerState !== OfferStatus.Draft;
    },

    supplierId() {
      return this.supplierInfo.id;
    },

    configuredSupplierCommission() {
      return this.supplierInfo?.commission;
    },

    supplierOrgNumber() {
      return this.supplierInfo?.orgNumber ?? "";
    },

    supplierRequest() {
      return this.tender.priceRequests[0].supplierRequests.find(
        (supplierRequest) => supplierRequest.supplierId === this.supplierId,
      );
    },

    customerOrganizationName() {
      return this.tender?.customerOrganization?.name;
    },

    goBackFromOfferModalCms() {
      if (this.pageCms && this.pageCms.pageSections) {
        const cmsItem = getCmsItem(this.pageCms.pageSections, "saveOfferModal");
        return cmsItem ? cmsItem : {};
      }
      return {};
    },

    defaultTitle() {
      return this.pageCms ? this.pageCms.title : "";
    },

    pageTitle() {
      return this.localOffer.nickName.trim() || this.defaultTitle;
    },

    editOfferNameButtonIcon() {
      const button = getCmsItem(this.pageCms?.buttons, "changeNameButton");
      const iconUrl = getIconUrl(button.icons, "editIcon");
      return iconUrl || "";
    },

    editOfferNameButtonText() {
      return getCmsItemText(this.pageCms?.buttons, "changeNameButton");
    },

    changeNameFormCms() {
      if (this.pageCms && this.pageCms.pageSections) {
        const cmsItem = getCmsItem(this.pageCms.forms, "changeNameForm");
        return cmsItem ? cmsItem : {};
      }
      return {};
    },

    showPageActionButtons() {
      return this.pageIsReady && !this.pageIsReadOnly;
    },

    saveButtonDisabled() {
      return !this.pageIsReady;
    },

    localOfferMeetsMinimumSendRequirements() {
      return (
        (this.localOffer.fixedCostActivities.length ||
          this.localOffer.variableCostActivities.length) &&
        this.localOffer.descriptions.length
      );
    },

    lastSaveDate() {
      const createdOn = this.offerStateChange?.createdOn;
      return createdOn ? dayjs(createdOn).format("HH:mm - DD.MM.YYYY") : "";
    },

    offerStateChange() {
      if (!this.serviceOffer) {
        return null;
      }
      return this.serviceOffer.offerStateChanges.find(
        (offerStateChange) =>
          offerStateChange.stateTo === this.serviceOffer.offerState,
      );
    },

    lastSavedLocalOffer() {
      return !this.serviceOffer
        ? this.getDefaultLocalOffer()
        : extractLocalOfferFromServiceOffer(
            this.serviceOffer,
            this.updatedTender,
            this.serviceTypesCms,
            this.configuredSupplierCommission,
          );
    },

    hasUnsavedChanges() {
      if (this.pageIsReadOnly) {
        return false;
      }
      const lastSavedOfferDTO = this.getEditOfferDTOFromLocalOffer(
        this.lastSavedLocalOffer,
        this.serviceOffer,
        this.updatedTender,
      );
      const currentLocalOfferDTO = this.getEditOfferDTOFromLocalOffer(
        this.localOffer,
        this.serviceOffer,
        this.updatedTender,
      );
      return !isEqual(lastSavedOfferDTO, currentLocalOfferDTO);
    },

    isWantedStartDateInThePast() {
      const { wantedStartDate } = this.localOffer;
      if (!wantedStartDate) {
        return true;
      }
      return dayjs(wantedStartDate).isBefore(dayjs(), "day");
    },

    isDuplicateOffer() {
      return "duplicate" in this.$route.query;
    },

    computedOfferName() {
      const baseName = this.computeBaseOfferName();
      return this.isDuplicateOffer ? `${baseName} (Kopi)` : baseName;
    },

    serviceOffer() {
      const offerId = this.$route.params.offerId;
      if (!offerId) {
        return null;
      }
      return getServiceOfferFromTender(parseInt(offerId), this.updatedTender);
    },

    hasFrameAgreement() {
      return this.supplierRequest?.hasFrameAgreement ?? false;
    },

    frameAgreementId() {
      return this.supplierRequest?.frameAgreementId || null;
    },
  },

  async created() {
    try {
      this.updatedTender = this.tender;
      await this.initializePageData();
      await this.initializeLocalOffer();
      await this.initializeOfferName();
      this.pageIsReady = true;
    } catch (error) {
      console.error("An error occurred while initializing the page:", error);
    }
  },

  methods: {
    async initializeOfferName() {
      const baseName = await this.computeBaseOfferName();
      this.offerName = this.isDuplicateOffer ? `${baseName} (Kopi)` : baseName;
    },

    async initializePageData() {
      const [
        frequencyOptionsResponse,
        editOfferPageCmsResponse,
        serviceActivities,
      ] = await Promise.all([
        getActivityFormFrequencyOptions(),
        cmsService.getEditSupplierOfferPage(),
        cmsService.getServiceActivities(),
        this.fetchFrameAgreement(),
      ]);

      this.serviceActivitiesCms = serviceActivities;
      this.pageCms = editOfferPageCmsResponse[0];
      this.frequencyOptions = frequencyOptionsResponse.data;
    },

    async initializeLocalOffer() {
      const localOffer = await this.getInitialLocalOffer();

      if (localOffer && this.shouldFetchOldMessages()) {
        await this.fetchOfferConversationData(localOffer);
      }

      localOffer.wantedStartDate = await this.getInitialStartDate();
      this.updateLocalOffer(localOffer);

      if (this.serviceOffer) {
        this.serviceOfferConfirmed = true;
      } else {
        this.showEditOfferNameModal = true;
      }
    },

    async fetchOfferConversationData(localOffer) {
      await this.fetchOldManualConversationMessages();
      localOffer.descriptions = this.getOfferConversationMessages();
      localOffer.attachments = this.getOfferConversationMessageAttachments();
    },

    async getReusableServiceOffer() {
      const reusableOfferId = parseInt(this.$route.query["reusable-offer"]);
      const reusableTenderId = parseInt(this.$route.query["reusable-tender"]);

      const tenderId = reusableTenderId || this.$route.params.tenderId;
      const matchingTender = await this.fetchTenderDetails(tenderId);

      console.log("matchingTender", matchingTender);
      return matchingTender
        ? getServiceOfferFromTender(reusableOfferId, matchingTender)
        : undefined;
    },

    async getInitialLocalOffer() {
      if (this.serviceOffer) {
        return this.extractLocalOfferFromServiceOffer(this.serviceOffer);
      }

      const reusableServiceOffer = await this.getReusableServiceOffer();

      if (reusableServiceOffer) {
        const reusableTenderId = parseInt(this.$route.query["reusable-tender"]);

        const tenderId = reusableTenderId || this.$route.params.tenderId;
        const matchingTender = await this.fetchTenderDetails(tenderId);
        return this.extractLocalOfferFromServiceOffer(
          reusableServiceOffer,
          matchingTender,
        );
      }

      return this.getDefaultLocalOffer();
    },

    extractLocalOfferFromServiceOffer(
      serviceOffer,
      tender = this.updatedTender,
    ) {
      const localOffer = extractLocalOfferFromServiceOffer(
        serviceOffer,
        tender,
        this.serviceTypesCms,
        this.configuredSupplierCommission,
      );

      // Get terminationPeriodInMonths from supplierRequest if available
      const terminationPeriodInMonths =
        this.supplierRequest?.terminationPeriodInMonths || 3;

      localOffer.noticePeriod = terminationPeriodInMonths;
      return localOffer;
    },

    async getEditOfferDTOFromLocalOffer(
      specifiedLocalOffer,
      serviceOffer,
      tender,
    ) {
      const priceRequest = tender.priceRequests[0];
      const wantedStartDate = this.formatDate(
        specifiedLocalOffer.wantedStartDate,
      );
      const clonedAttachments = this.getClonedAttachments(
        specifiedLocalOffer,
        serviceOffer,
      );
      const clonedAttachmentsDto =
        await this.getClonedAttachmentsDto(clonedAttachments);

      const dto = {
        tenderId: tender.tenderId,
        priceRequestId: priceRequest.id,
        supplierId: this.supplierId,
        serviceTypeId: priceRequest.serviceTypeId,
        userId: this.getUserId(),
        serviceOfferExtraId: serviceOffer?.serviceOfferExtra?.id,
        variableCostActivities:
          getVariableCostActivitiesDTOFromLocalOffer(specifiedLocalOffer),
        fixedCostActivities: getFixedCostActivitiesDTOFromLocalOffer(
          specifiedLocalOffer,
          serviceOffer,
          tender,
          this.configuredSupplierCommission,
          this.frequencyOptions,
          this.serviceTypesCms,
        ),
        removedSegmentGroupIds: getRemovedActivityIds(
          specifiedLocalOffer,
          serviceOffer,
        ),
        addedAttachments: this.getUploadedAttachments(
          specifiedLocalOffer,
          serviceOffer,
        ),
        cloneAttachments:
          clonedAttachmentsDto?.cloneAttachmentDetails?.length > 0
            ? clonedAttachmentsDto
            : null,
        removedAttachmentIds: getRemovedAttachmentsDTO(
          specifiedLocalOffer,
          serviceOffer,
        ),
        descriptions: getDescriptionsDTOFromLocalOffer(specifiedLocalOffer),
        nickName: specifiedLocalOffer.nickName,
        wantedStartDate,
      };

      // Add terminationPeriodInMonths if noticePeriod exists and there are repeating costs
      const hasRepeatingCosts = specifiedLocalOffer.fixedCostActivities.some(
        (activity) => activity.frequencyGroup.toLowerCase() !== "fixed",
      );

      if (hasRepeatingCosts) {
        dto.terminationPeriodInMonths = specifiedLocalOffer.noticePeriod || 3;
      } else {
        dto.terminationPeriodInMonths = null;
      }

      return dto;
    },

    formatDate(date) {
      return date ? dayjs(date).format("YYYY-MM-DD") : null;
    },

    getUserId() {
      return this.$store.state.api.user.userId;
    },

    getUploadedAttachments(specifiedLocalOffer, serviceOffer) {
      const addedAttachments = getAddedAttachmentsDTO(
        specifiedLocalOffer,
        serviceOffer,
      );
      return addedAttachments?.filter((attachment) => attachment.file);
    },

    getClonedAttachments(specifiedLocalOffer, serviceOffer) {
      const addedAttachments = getAddedAttachmentsDTO(
        specifiedLocalOffer,
        serviceOffer,
      );
      return addedAttachments?.filter((attachment) => !attachment.file);
    },

    async getClonedAttachmentsDto(clonedAttachments) {
      const reusableServiceOffer = await this.getReusableServiceOffer();

      const tenderId = this.$route.params.tenderId;
      const reusableTender = await this.fetchTenderDetails(tenderId);

      const priceRequestId =
        reusableServiceOffer?.serviceOfferExtra?.priceRequestId;

      const priceRequest = this.getPriceRequest(
        reusableServiceOffer,
        reusableTender,
      );

      if (!priceRequest) {
        return null;
      }

      const cloneAttachmentDetails = clonedAttachments?.map(
        ({ id, fileName }) => ({
          attachmentId: id,
          fileName,
        }),
      );

      return {
        priceRequestId: priceRequestId,
        cloneAttachmentDetails,
      };
    },

    getPriceRequest(serviceOffer, tender) {
      const priceRequest = getPriceRequestForGivenServiceOffer(
        serviceOffer,
        tender,
      );
      return priceRequest || tender.priceRequests[0];
    },

    async fetchTenderDetails(tenderId, forceFresh = false) {
      const cacheKey = `${this.supplierId}-${tenderId}`;

      if (this.pendingFetches.has(cacheKey)) {
        return this.pendingFetches.get(cacheKey);
      }

      const lastFetch = this.lastFetchTimestamp.get(cacheKey);
      const isCacheStale = lastFetch && Date.now() - lastFetch > this.CACHE_TTL;

      if (!forceFresh && !isCacheStale && this.tenderCache.has(cacheKey)) {
        return this.tenderCache.get(cacheKey);
      }

      try {
        const fetchPromise = getTender({
          supplierId: this.supplierId,
          tenderId,
        });
        this.pendingFetches.set(cacheKey, fetchPromise);

        const { data } = await fetchPromise;

        this.tenderCache.set(cacheKey, data);
        this.lastFetchTimestamp.set(cacheKey, Date.now());

        return data;
      } catch (error) {
        console.error(
          `Error fetching tender details for tenderId ${tenderId}:`,
          error,
        );
        this.tenderCache.delete(cacheKey);
        throw error;
      } finally {
        this.pendingFetches.delete(cacheKey);
      }
    },

    async computeBaseOfferName() {
      const reusableServiceOffer = await this.getReusableServiceOffer();
      if (reusableServiceOffer) {
        return reusableServiceOffer.serviceOfferExtra?.nickname || "";
      }

      const serviceTypesCms = await this.serviceTypesCms;
      const serviceCategoryName = getServiceCategory(
        this.tender,
        serviceTypesCms,
      );
      return serviceCategoryName;
    },

    showFeedbackMessage(feedbackType, feedbackCmsLabel, messageInfo = "") {
      if (this.pageIsReadOnly) return;
      let messageTitle = "";
      if (this.pageCms) {
        const feedbackMessagesSection = getCmsItem(
          this.pageCms.pageSections,
          "feedbackMessages",
        );
        messageTitle = getCmsItemText(
          feedbackMessagesSection.otherText,
          feedbackCmsLabel,
        );
      }
      this.feedbackMessages.push({
        id: uuidv4(),
        type: feedbackType,
        messageTitle: messageTitle,
        messageInfo: messageInfo,
      });
    },

    removeFeedbackMessage(message) {
      for (let i = this.feedbackMessages.length - 1; i >= 0; i--) {
        if (this.feedbackMessages[i].id === message.id) {
          this.feedbackMessages.splice(i, 1);
        }
      }
    },

    clearFeedbackMessages() {
      this.feedbackMessages = [];
    },

    sendOffer() {
      this.clearFeedbackMessages();

      if (this.hasUnsavedChangesOrNoServiceOffer()) {
        this.showUnsavedChangesFeedbackMessage();
        return;
      }

      if (this.offerHasInvalidContent()) {
        this.showInvalidContentFeedbackMessage();
        return;
      }

      const daysToDeadline = this.getDaysToDeadline(this.tender);

      if (daysToDeadline !== undefined && daysToDeadline < 0) {
        this.showLateOfferModal = true;
      } else {
        // Proceed with normal send offer flow
        this.showSendOfferModal = true;
      }
    },

    handleLateOfferOk() {
      this.showLateOfferModal = false;
      this.showSendOfferModal = true;
    },

    hasUnsavedChangesOrNoServiceOffer() {
      return !this.serviceOffer;
    },

    showUnsavedChangesFeedbackMessage() {
      this.showFeedbackMessage(
        FeedbackType.Error,
        CmsFeedbackLabels.sendHasUnsavedChanges,
      );
    },

    offerHasInvalidContent() {
      return (
        !this.localOfferMeetsMinimumSendRequirements ||
        this.isWantedStartDateInThePast
      );
    },

    showInvalidContentFeedbackMessage() {
      if (!this.localOfferMeetsMinimumSendRequirements) {
        this.showFeedbackMessage(
          FeedbackType.Error,
          CmsFeedbackLabels.sendRequirementsError,
        );
      } else if (this.isWantedStartDateInThePast) {
        this.showFeedbackMessage(
          FeedbackType.Error,
          CmsFeedbackLabels.invalidWantedStartDateError,
        );
      }
    },

    async saveOffer() {
      if (!this.serviceOfferConfirmed || this.isSavingOffer) {
        this.queueSave = true;
        return;
      }

      this.queueSave = false;
      this.clearFeedbackMessages();

      if (this.serviceOffer && !this.hasUnsavedChanges) {
        this.showFeedbackMessage(FeedbackType.Success, CmsFeedbackLabels.saved);
        return;
      }

      this.isSavingOffer = true;

      try {
        const localOfferBeforeSave = cloneDeep(this.localOffer);
        const offerDTO = await this.getEditOfferDTOFromLocalOffer(
          localOfferBeforeSave,
          this.serviceOffer,
          this.updatedTender,
        );

        const finalizedOfferDTO = await this.finalizeOfferDTO(offerDTO);
        this.sanitizeActivitySegmentGroupIds(finalizedOfferDTO);

        if (this.serviceOffer) {
          await this.updateExistingOffer(finalizedOfferDTO);
        } else {
          await this.createNewOffer(finalizedOfferDTO);
        }

        this.clearTenderCache(this.tender.tenderId);
        const freshTender = await this.fetchTenderDetails(
          this.tender.tenderId,
          true,
        );
        await this.updateTenderAndServiceOffer(freshTender);

        this.updateLocalOfferOnSave(localOfferBeforeSave, offerDTO);
        this.showSuccessFeedback();

        if (this.queueSave) {
          this.saveOffer();
        }
      } catch (error) {
        this.handleSaveError(error);
      } finally {
        this.isSavingOffer = false;
      }
    },

    async updateExistingOffer(finalizedOfferDTO) {
      await updateSupplierTenderOffer({
        serviceOfferId: this.serviceOffer.id,
        data: finalizedOfferDTO,
      });
    },

    async createNewOffer(finalizedOfferDTO) {
      const createOfferResponse =
        await createSupplierTenderOffer(finalizedOfferDTO);
      await this.$router.replace({
        name: this.$route.name,
        params: { ...this.$route.params, offerId: createOfferResponse.data.id },
      });
    },

    async fetchConversations() {
      const supplierRequestId = this.supplierRequest?.id;
      if (!supplierRequestId) {
        console.error("Supplier request ID is undefined");
        return;
      }

      try {
        const response =
          await supplierCommunicationService.fetchSupplierConversations(
            supplierRequestId,
          );
        return response.data;
      } catch (error) {
        console.error("Failed to fetch conversations:", error);
      }
    },

    async sendMessage(message) {
      try {
        const conversationMessages = await this.fetchConversations();

        let conversationId = null;
        if (conversationMessages?.length) {
          conversationId = conversationMessages[0].message.conversationId;
        }

        const user = this.$store.state.api.user;
        if (!user) {
          throw new Error("User not found in the store.");
        }

        const { userId, name, email } = user;

        const messageDto = {
          conversationId,
          messageType: MessageType.PriceOffer,
          messageBody: message,
          attachments: [],
          senderUserId: userId,
          senderName: name,
          senderEmail: email,
          supplierId: this.supplierId,
          supplierRequestId: this.supplierRequest?.id,
          serviceOfferId: this.serviceOffer?.id,
          tenderId: this.tender.tenderId,
          rootMessage: !conversationId,
          messageDomain: MessageDomain.SupplierCustomer,
          price: null,
        };

        const newMessageResponse =
          await supplierCommunicationService.sendMessage(messageDto);
        return newMessageResponse;
      } catch (error) {
        console.error("Error sending message:", error);
        throw error;
      }
    },

    showSuccessFeedback() {
      this.clearFeedbackMessages();
      this.showFeedbackMessage(FeedbackType.Success, CmsFeedbackLabels.saved);
    },

    handleSaveError(error) {
      console.error("Error saving offer:", error);
      const errorMessage = error?.response?.data;
      this.showFeedbackMessage(
        FeedbackType.Error,
        CmsFeedbackLabels.saveError,
        errorMessage ? JSON.stringify(errorMessage) : "",
      );
    },

    async finalizeOfferDTO(offerDTO) {
      const finalizedOfferDTO = cloneDeep(offerDTO);

      if (typeof finalizedOfferDTO.noticePeriod === "number") {
        finalizedOfferDTO.terminationPeriodInMonths =
          finalizedOfferDTO.noticePeriod;
        delete finalizedOfferDTO.noticePeriod;
      }

      finalizedOfferDTO.addedAttachments = await Promise.all(
        finalizedOfferDTO.addedAttachments.map(async (attachment) => {
          const fileContent = await convertFileToBase64(attachment.file);
          return {
            fileName: attachment.file.name,
            fileContent,
          };
        }),
      );
      return finalizedOfferDTO;
    },

    sanitizeActivitySegmentGroupIds(offerDTO) {
      const activityTypes = ["fixedCostActivities", "variableCostActivities"];
      activityTypes.forEach((activityType) => {
        offerDTO[activityType].forEach((activity) => {
          activity.segmentGroupId =
            typeof activity.segmentGroupId === "number"
              ? activity.segmentGroupId
              : null;
        });
      });
    },

    async updateLocalOfferOnSave(localOfferBeforeSave, offerDTO) {
      const savedLocalOffer = extractLocalOfferFromServiceOffer(
        this.serviceOffer,
        this.updatedTender,
        this.serviceTypesCms,
        this.configuredSupplierCommission,
      );
      const savedOfferDTO = await this.getEditOfferDTOFromLocalOffer(
        savedLocalOffer,
        this.serviceOffer,
        this.updatedTender,
      );

      const updatedLocalOfferBeforeSave = this.getUpdatedLocalOffer(
        localOfferBeforeSave,
        offerDTO,
        savedOfferDTO,
      );
      const updatedOfferDTO = await this.getEditOfferDTOFromLocalOffer(
        updatedLocalOfferBeforeSave,
        this.serviceOffer,
        this.updatedTender,
      );

      const localOfferToUpdate = !isEqual(updatedOfferDTO, savedOfferDTO)
        ? this.localOffer
        : savedLocalOffer;
      const updatedLocalOffer = this.getUpdatedLocalOffer(
        localOfferToUpdate,
        offerDTO,
        savedOfferDTO,
      );
      this.updateLocalOffer(updatedLocalOffer);
    },

    getUpdatedLocalOffer(localOfferToUpdate, offerDTO, savedOfferDTO) {
      let updatedLocalOffer = this.updateLocalOfferActivityIdsOnSave(
        cloneDeep(localOfferToUpdate),
        offerDTO,
        savedOfferDTO,
      );
      updatedLocalOffer = this.updateLocalOfferAttachmentIdsOnSave(
        updatedLocalOffer,
        this.serviceOffer,
      );
      return updatedLocalOffer;
    },

    updateLocalOfferActivityIdsOnSave(
      currentLocalOffer,
      offerDTOBeforeSave,
      offerDTOAfterSave,
    ) {
      const activityTypes = ["fixedCostActivities", "variableCostActivities"];
      activityTypes.forEach((activityType) => {
        offerDTOBeforeSave[activityType].forEach((itemBeforeSave) => {
          const itemExists = offerDTOAfterSave[activityType].some(
            (itemAfterSave) =>
              itemAfterSave.segmentGroupId === itemBeforeSave.segmentGroupId,
          );
          if (itemExists) {
            return;
          }

          const matchingSavedItem = this.findMatchingSavedItem(
            itemBeforeSave,
            offerDTOAfterSave[activityType],
            offerDTOBeforeSave[activityType],
          );
          if (!matchingSavedItem) {
            return;
          }

          this.updateMatchingCurrentItem(
            currentLocalOffer[activityType],
            itemBeforeSave.segmentGroupId,
            matchingSavedItem.segmentGroupId,
          );
        });
      });
      return currentLocalOffer;
    },

    findMatchingSavedItem(
      itemBeforeSave,
      activitiesAfterSave,
      activitiesBeforeSave,
    ) {
      return activitiesAfterSave.find((itemAfterSave) =>
        this.isNewMatchingActivity(
          itemBeforeSave,
          itemAfterSave,
          activitiesBeforeSave,
        ),
      );
    },

    isNewMatchingActivity(itemBeforeSave, itemAfterSave, activitiesBeforeSave) {
      const itemIsNew = !activitiesBeforeSave.some(
        (activity) => activity.segmentGroupId === itemAfterSave.segmentGroupId,
      );
      if (!itemIsNew) {
        return false;
      }

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { segmentGroupId: _, ...activityACopy } = itemBeforeSave;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { segmentGroupId: __, ...activityBCopy } = itemAfterSave;
      return isEqual(activityACopy, activityBCopy);
    },

    updateMatchingCurrentItem(currentItems, beforeSaveId, afterSaveId) {
      const matchingCurrentItem = currentItems.find(
        (currentItem) => currentItem.id === beforeSaveId,
      );
      if (matchingCurrentItem) {
        matchingCurrentItem.id = afterSaveId;
      }
    },

    updateLocalOfferAttachmentIdsOnSave(currentLocalOffer, serviceOffer) {
      if (
        !serviceOffer?.serviceOfferExtra ||
        !serviceOffer?.serviceOfferExtra.attachments
      ) {
        return currentLocalOffer;
      }

      const serviceOfferAttachments =
        serviceOffer.serviceOfferExtra.attachments;

      currentLocalOffer.attachments = currentLocalOffer.attachments.map(
        (localAttachment) => {
          const matchingServiceOfferAttachment = serviceOfferAttachments.find(
            (attachment) =>
              attachment.attachmentId === localAttachment.id ||
              this.isNewMatchingAttachment(
                attachment,
                localAttachment,
                currentLocalOffer.attachments,
              ),
          );

          return matchingServiceOfferAttachment
            ? {
                ...localAttachment,
                id: matchingServiceOfferAttachment.attachmentId,
              }
            : localAttachment;
        },
      );

      return currentLocalOffer;
    },

    isNewMatchingAttachment(
      serviceOfferAttachment,
      localAttachment,
      localAttachments,
    ) {
      const itemIsNew = !localAttachments.some(
        (attachment) => attachment.id === serviceOfferAttachment.attachmentId,
      );
      return (
        itemIsNew &&
        localAttachment.fileName === serviceOfferAttachment.fileName
      );
    },

    updateOfferName(name) {
      this.serviceOfferConfirmed = true;
      const localOfferCopy = cloneDeep(this.localOffer);
      localOfferCopy.nickName = name;
      this.updateAndSaveLocalOffer(localOfferCopy);
    },

    openEditOfferNameModal() {
      this.showEditOfferNameModal = true;
    },

    getButtonText(label) {
      const buttons = this.pageCms?.buttons;
      return buttons ? getCmsItemText(buttons, label) : "";
    },

    async updateTenderAndServiceOffer(tender) {
      await this.$store.dispatch("offers/updateTender", tender);
      this.updatedTender = tender;
    },

    async handleOfferSent(args) {
      const tender = args.tender;
      const message = args.message;
      await this.updateTenderAndServiceOffer(tender);
      const localOffer = extractLocalOfferFromServiceOffer(
        this.serviceOffer,
        this.updatedTender,
        this.serviceTypesCms,
        this.configuredSupplierCommission,
      );
      this.updateLocalOffer(localOffer);
      if (message) {
        await this.sendMessage(message);
      }
      this.navigateToAgreements(tender);
    },

    navigateToAgreements(tender) {
      const { tenderId } = tender;
      const priceRequestId = tender?.priceRequests?.[0]?.id || null;
      if (!priceRequestId) return;
      return this.$router
        .push({
          name: RouteNames.MY_REQUESTS,
          query: { tenderId, priceRequestId },
        })
        .catch((err) => {
          if (err.name !== "NavigationDuplicated") {
            throw err;
          }
        });
    },

    updateAndSaveLocalOffer(newOffer) {
      this.updateLocalOffer(newOffer);
      this.saveOffer();
    },

    updateLocalOffer(newOffer) {
      this.localOffer = { ...newOffer };
    },

    getDefaultLocalOffer() {
      return {
        nickName: "",
        wantedStartDate: null,
        descriptions: [],
        fixedCostActivities: [],
        variableCostActivities: [],
        attachments: [],
      };
    },

    goBackWithModalConfirmation() {
      this.goBack();
    },

    goBack() {
      this.$router.back();
    },

    getOfferConversationMessages() {
      if (
        this.conversations &&
        this.conversations?.length &&
        this.conversations?.[0].thread?.length
      ) {
        const sortedMessages = this.conversations?.[0].thread
          .slice()
          .sort((conversationA, conversationB) => {
            const latestDateA = new Date(conversationA.createdOn);
            const latestDateB = new Date(conversationB.createdOn);
            return latestDateA.getTime() - latestDateB.getTime();
          });
        const conversationDescriptions = [];
        sortedMessages.forEach((offerConversation, index) => {
          const offerConversationDescriptionExists =
            this.localOffer.descriptions.find(
              (description) => description.id === offerConversation.id,
            );
          if (!offerConversationDescriptionExists) {
            conversationDescriptions.push({
              id: index,
              title: offerConversation?.senderName,
              description: offerConversation.messageBody,
            });
          }
        });

        return conversationDescriptions;
      } else {
        return [];
      }
    },

    getOfferConversationMessageAttachments() {
      if (
        this.conversations &&
        this.conversations?.length &&
        this.conversations?.[0].thread?.length
      ) {
        const sortedMessages = this.conversations?.[0].thread
          .slice()
          .sort((conversationA, conversationB) => {
            const latestDateA = new Date(conversationA.createdOn);
            const latestDateB = new Date(conversationB.createdOn);
            return latestDateA.getTime() - latestDateB.getTime();
          });
        const conversationMessageAttachments = [];
        sortedMessages.forEach((message) => {
          if (Array.isArray(message.attachments)) {
            message.attachments.forEach((attachment) => {
              const attachmentExists = this.localOffer.attachments.some(
                (localAttachment) =>
                  localAttachment.id === attachment.attachmentId,
              );

              if (!attachmentExists) {
                conversationMessageAttachments.push({
                  id: attachment.attachmentId,
                  file: attachment.attachmentUri,
                  fileName: attachment.fileName,
                  attachmentUri: attachment.attachmentUri,
                });
              }
            });
          }
        });

        return conversationMessageAttachments;
      } else {
        return [];
      }
    },

    async fetchConversationMessages(conversationId) {
      try {
        const response =
          await supplierCommunicationService.fetchConversationMessages(
            conversationId,
          );
        if (!this.conversations) {
          this.conversations = [];
        }
        const matchingConversation = this.conversations.find(
          (conversation) =>
            conversation.message.conversationId === conversationId,
        );
        if (matchingConversation) {
          Object.assign(matchingConversation, response.data);
        } else {
          this.conversations.push(response.data);
        }
      } catch (warn) {
        console.warn("Error fetching conversation messages:", warn);
      }
    },

    async fetchOldManualConversationMessages() {
      try {
        await this.fetchConversations();

        if (this.conversations?.length) {
          const conversationId = this.conversations[0].message.conversationId;
          await this.fetchConversationMessages(conversationId);
          return this.getOfferConversationMessages();
        } else {
          throw new Error("No conversations are available.");
        }
      } catch (warn) {
        return [];
      }
    },

    shouldFetchOldMessages() {
      return (
        this.serviceOffer &&
        this.serviceOffer.offerState !== OfferStatus.Draft &&
        this.serviceOffer?.serviceOfferExtra === null
      );
    },

    getSelectedServiceTypePackagePortfolio() {
      if (!this.tender?.supplierPortfolios) {
        return null;
      }

      const { supplierPortfolios } = this.tender;

      if (!Array.isArray(supplierPortfolios)) {
        return null;
      }

      return supplierPortfolios
        .flatMap(
          ({ serviceTypePackagePortfolio }) => serviceTypePackagePortfolio,
        )
        .find(
          ({ offerStatus }) =>
            offerStatus === OfferStatus.Accepted ||
            offerStatus === OfferStatus.Completed,
        );
    },

    getInitialStartDate() {
      const serviceOfferWantedStartDate =
        this.serviceOffer?.serviceOfferExtra?.wantedStartDate;

      if (
        serviceOfferWantedStartDate &&
        dayjs(serviceOfferWantedStartDate).isValid()
      ) {
        return dayjs(serviceOfferWantedStartDate).toDate();
      }

      const customerWantedStartDate = dayjs(
        this.tender.priceRequests[0].wantedStartDate,
      );
      const minimumStartDate = dayjs()
        .add(this.minimumDaysToStartDate, "day")
        .toDate();

      if (customerWantedStartDate.isAfter(dayjs(minimumStartDate))) {
        return customerWantedStartDate.toDate();
      }

      return minimumStartDate;
    },

    handleOnNewTabClick() {
      window.open(import.meta.env.VITE_APP_NEED_HELP_OFFER_URL, "_blank");
    },

    getDaysToDeadline(tender) {
      const deadlineDate = dayjs.utc(
        this.getTenderResponseDeadlineDate(tender),
      );
      const now = dayjs.utc();

      if (!deadlineDate.isValid()) {
        console.warn(`Invalid deadline date for tender ${tender.tenderId}`);
        return undefined;
      }

      return Math.ceil(deadlineDate.diff(now, "day", true));
    },

    isValidPriceRequest(pr) {
      return (
        !!pr &&
        typeof pr.offerResponsePeriodInDays === "number" &&
        pr.offerResponsePeriodInDays > 0
      );
    },

    getValidRequestDates(supplierRequests) {
      return supplierRequests
        .flatMap((request) => request.requestStateChanges)
        .filter((change) => change.stateTo === RequestState.PRICE_REQUESTED)
        .map((change) => dayjs(change.createdOn))
        .filter((date) => date.isValid());
    },

    getTenderResponseDeadlineDate(tender) {
      if (!tender?.priceRequests?.length) return undefined;

      const priceRequest = tender.priceRequests[0];
      if (!this.isValidPriceRequest(priceRequest)) return undefined;

      const allValidDates = [
        ...this.getValidRequestDates(priceRequest.supplierRequests),
        dayjs(tender.createdOn),
      ].filter((date) => date.isValid());

      if (!allValidDates.length) return undefined;

      const latestDate = allValidDates.reduce((latest, current) =>
        current.isAfter(latest) ? current : latest,
      );

      return addBusinessDays(
        latestDate.toDate(),
        priceRequest.offerResponsePeriodInDays,
      );
    },

    clearTenderCache(tenderId = null) {
      if (tenderId) {
        const cacheKey = `${this.supplierId}-${tenderId}`;
        this.tenderCache.delete(cacheKey);
        this.lastFetchTimestamp.delete(cacheKey);
      } else {
        this.tenderCache.clear();
        this.lastFetchTimestamp.clear();
      }
    },

    beforeDestroy() {
      this.clearTenderCache();
    },

    async fetchFrameAgreement() {
      if (
        !this.hasFrameAgreement ||
        !this.frameAgreementId ||
        !this.tender.customerOrganizationId
      ) {
        this.isLoadingFrameAgreement = false;
        return;
      }

      this.isLoadingFrameAgreement = true;
      try {
        const response = await getFrameAgreement({
          organizationId: this.tender.customerOrganizationId,
          frameAgreementId: this.frameAgreementId,
        });
        this.frameAgreement = response.data;
      } catch (err) {
        console.error("Error fetching frame agreement:", err);
        this.frameAgreement = null;
      } finally {
        this.isLoadingFrameAgreement = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.offer-view {
  color: $color-black;
  margin-bottom: 8rem;

  &__header-buttons {
    display: flex;
    gap: 1.5rem;

    > button {
      padding: 0.625rem 1rem;
    }
  }

  &__title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &__title {
    font-weight: 600;
    font-size: $font-size-2xl;
  }

  &__new-tab-button {
    display: flex;
    height: 40px;
    padding: 0px 16px;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    background: var(--Yellow-Dark, #ecba4e);
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    color: var(--Black, #1d1d1d);
    font-weight: 700;

    img {
      width: 24px;
      height: 24px;
    }

    &:hover {
      background: rgba(236, 186, 78, 0.8);
    }

    &:active {
      background: rgba(236, 186, 78, 0.6);
    }
  }

  &__container {
    max-width: 61.25rem;
    margin: 0 auto;
    padding-top: 4.75rem;
    padding-bottom: 8rem;
  }

  &__feedback-messages {
    position: fixed;
    top: 4.5rem;
    left: 50%;
    transform: translateX(-50%);
    width: 556px;
    margin: 0 auto;
  }

  &__feedback-message {
    padding-top: 0.5rem;
  }

  &__change-offer-name-button {
    margin-top: 0.5rem;
  }

  &__agreement-partners {
    margin-top: 2rem;
  }

  &__start-date {
    margin-top: 2.5rem;
  }

  &__activities-and-cost {
    margin-top: 2.5rem;
  }

  &__work-description {
    margin-top: 2.5rem;
  }

  &__terms {
    margin-top: 2.5rem;
  }

  &__product-description-uploads {
    margin-top: 2.5rem;
  }

  &__terms-and-conditions {
    margin-top: 2.5rem;
  }

  &__loader {
    padding: 4rem 0;
    display: flex;
    justify-content: center;
  }
}

:deep(*) {
  font-family: "Montserrat", sans-serif;
}
</style>
