<template>
  <div v-if="showComponent" class="work-description">
    <div class="work-description__title">
      {{ title }}
    </div>
    <div class="work-description__description">
      <custom-block-content :blocks="descriptionContent" />
    </div>
    <div v-if="localDescriptions.length" class="work-description__list">
      <div
        v-for="(descriptionItem, index) in localDescriptions"
        :key="descriptionItem.id"
        class="work-description__list-item"
      >
        <work-description-item
          :count="index + 1"
          :title="descriptionItem.title"
          :description="descriptionItem.description"
          :read-only="readOnly"
          @edit-item="openNewDescriptionModal(descriptionItem)"
          @delete-item="onDeleteWorkDescriptionItem(descriptionItem)"
        />
      </div>
    </div>
    <div v-else class="work-description__empty-state">
      <empty-state text="Ingen beskrivelse lagt til" />
    </div>
    <div v-if="!readOnly" class="work-description__new-point-button">
      <secondary-button
        :icon-src="newPointButtonIcon"
        :text="newPointButtonText"
        @click="openNewDescriptionModal"
      />
    </div>
    <delete-work-description-item-modal
      v-if="workDescriptionToDelete"
      v-model="showDeleteWorkDescriptionModal"
      :description-item="workDescriptionToDelete"
      @item-deleted="onWorkDescriptionItemDeleted"
    />
    <new-work-description-modal
      v-model="showNewWorkDescriptionModal"
      :work-description="workDescriptionToEdit"
      :form-cms="newDescriptionFormCms"
      @work-description-added="handleNewWorkDescription"
      @add-new-work-description="workDescriptionToEdit = null"
    />
  </div>
</template>

<script>
import EmptyState from "@/components/EmptyState/EmptyState.vue";
import SecondaryButton from "@/components/Buttons/SecondaryButton.vue";
import WorkDescriptionItem from "@/components/SupplierOfferWorkDescription/WorkDescriptionItem.vue";
import DeleteWorkDescriptionItemModal from "@/components/SupplierOfferWorkDescription/DeleteWorkDescriptionItemModal.vue";
import NewWorkDescriptionModal from "@/components/NewWorkDescriptionModal/NewWorkDescriptionModal.vue";
import CustomBlockContent from "@/components/CustomBlockContent/CustomBlockContent.vue";
import cmsService from "@/services/cmsService";
import { cloneDeep, isEqual, sortBy } from "lodash";

export default {
  components: {
    CustomBlockContent,
    NewWorkDescriptionModal,
    DeleteWorkDescriptionItemModal,
    WorkDescriptionItem,
    SecondaryButton,
    EmptyState,
  },
  props: {
    pageCms: {
      type: Object,
      required: true,
    },
    offer: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["new-offer"],
  data() {
    return {
      showNewWorkDescriptionModal: false,
      showDeleteWorkDescriptionModal: false,
      workDescriptionToDelete: null,
      workDescriptionToEdit: null,
      localDescriptions: [],
    };
  },
  computed: {
    showComponent() {
      return !(this.readOnly && this.localDescriptions.length === 0);
    },
    newDescriptionFormCms() {
      return this.pageCms.forms.find(
        (section) => section.label === "newDescriptionForm",
      );
    },
    workDescriptionCms() {
      return this.pageCms.pageSections.find(
        (section) => section.label === "workDescription",
      );
    },
    title() {
      return this.workDescriptionCms.title;
    },
    descriptionContent() {
      return this.workDescriptionCms.content;
    },
    newPointButton() {
      return this.workDescriptionCms.buttons.find(
        (button) => button.label === "newPointButton",
      );
    },
    newPointButtonText() {
      return this.newPointButton.title;
    },
    newPointButtonIcon() {
      const icon = this.newPointButton.icons[0].icon;
      return cmsService.resolveImage(icon).url();
    },
  },
  watch: {
    "offer.descriptions": {
      handler() {
        if (
          this.checkIfDescriptionsAreEqual(
            this.offer.descriptions,
            this.localDescriptions,
          )
        ) {
          return;
        }
        this.localDescriptions = cloneDeep(this.offer.descriptions);
      },
      immediate: true,
      deep: true,
    },
    localDescriptions: {
      handler() {
        if (
          this.checkIfDescriptionsAreEqual(
            this.offer.descriptions,
            this.localDescriptions,
          )
        ) {
          return;
        }
        const offerCopy = cloneDeep(this.offer);
        offerCopy.descriptions = cloneDeep(this.localDescriptions);
        this.$emit("new-offer", offerCopy);
      },
      deep: true,
    },
  },
  methods: {
    handleNewWorkDescription(data) {
      const matchingDescription = this.localDescriptions.find(
        (workDescription) => workDescription.id === data.id,
      );

      if (matchingDescription) {
        Object.assign(matchingDescription, {
          title: data.title,
          description: data.description,
        });
      } else {
        const newDescription = {
          title: data.title,
          description: data.description,
        };
        this.localDescriptions.push(newDescription);
      }
    },

    onWorkDescriptionItemDeleted(descriptionItem) {
      const index = this.localDescriptions.findIndex(
        (desc) => desc.id === descriptionItem.id,
      );
      if (index !== -1) {
        this.localDescriptions.splice(index, 1);
      }
    },

    checkIfDescriptionsAreEqual(descriptionsA, descriptionsB) {
      const normalizeDescription = (desc) => {
        const normalized = {
          title: desc.title,
          description: desc.description || desc.body,
        };

        if (desc.id && typeof desc.id === "number") {
          normalized.id = desc.id;
        }

        return normalized;
      };

      const normalizedA = descriptionsA?.map(normalizeDescription) || [];
      const normalizedB = descriptionsB?.map(normalizeDescription) || [];

      return isEqual(
        sortBy(normalizedA, ["id", "title"]),
        sortBy(normalizedB, ["id", "title"]),
      );
    },

    onDeleteWorkDescriptionItem(descriptionItem) {
      this.workDescriptionToDelete = descriptionItem;
      this.$nextTick(() => {
        this.showDeleteWorkDescriptionModal = true;
      });
    },
    openNewDescriptionModal(workDescription = null) {
      this.workDescriptionToEdit = workDescription;
      this.showNewWorkDescriptionModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.work-description {
  &__title {
    font-weight: 600;
    font-size: $font-size-2xl;
  }

  &__description {
    margin: 0;
    font-weight: 400;
    font-size: $font-size-sm;
  }

  &__empty-state {
    margin-top: 1rem;
  }

  &__list {
    margin-top: 1rem;
  }

  &__list-item {
    &:not(:first-child) {
      margin-top: 1rem;
    }
  }

  &__new-point-button {
    margin-top: 1rem;
  }
}
</style>
