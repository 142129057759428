<template>
  <div class="user-welcome-view">
    <user-get-started
      :terms-and-conditions-link="TERMS_AND_CONDITIONS_LINK"
      :is-submitting="state.isSubmitting"
      :submission-error="state.submissionError"
      @submit="handleCtaClick"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, reactive, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { UserGetStarted } from "@really/frontend-commons-library";
import { useUserStore } from "@/stores/user";
import { useUsersApi } from "@/services/api/useUserApi";
import { RouteNames } from "@/router/types";
import { cloneDeep } from "lodash";
import { User } from "@/custom-types/GeneralTypes";
import _ from "lodash";
import { useSupplierStore } from "@/stores/supplier";

const TERMS_AND_CONDITIONS_LINK = import.meta.env
  .VITE_APP_TERMS_AND_CONDITIONS_LINK;

const router = useRouter();
const route = useRoute();
const userStore = useUserStore();
const { editUserInfo } = useUsersApi();
const supplierStore = useSupplierStore();

const state = reactive({
  isSubmitting: false,
  submissionError: "",
});

const user = computed(() => userStore.user);

const navigateToDestination = () => {
  const redirectUrl = route.query.redirect as string;
  if (redirectUrl) {
    router.replace(redirectUrl);
  } else {
    router.replace({ name: RouteNames.MY_REQUESTS });
  }
};

const handleCtaClick = async (preferences: User) => {
  if (!user.value) return;

  state.isSubmitting = true;
  state.submissionError = "";

  const userCopy = cloneDeep(user.value);
  const modifiedUser = _.omit(userCopy, [
    "phoneNumber",
    "orgNumbers",
    "otherCommunicationAccepted",
  ]);

  try {
    let orgNumber = userCopy.orgNumbers;

    // If orgNumber not available in user, try to get from supplier
    if (!orgNumber && supplierStore.supplier) {
      orgNumber = supplierStore.supplier.orgNumber;
    }

    const updatedUser = {
      ...modifiedUser,
      ...preferences,
      orgNumber: orgNumber || "",
    };

    await editUserInfo(updatedUser);
    userStore.updateUser(updatedUser);
    navigateToDestination();
  } catch (error) {
    console.error("Error updating user preferences:", error);
    state.submissionError =
      "Det oppstod en feil ved oppdatering av brukerpreferanser";
  } finally {
    state.isSubmitting = false;
  }
};

onMounted(() => {
  if (!user.value || user.value?.termsAndConditionsAccepted) {
    navigateToDestination();
  }
});
</script>

<style lang="scss" scoped>
.user-welcome-view {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 2rem;
  background-color: $color-primary-pink-dark;
}
</style>
