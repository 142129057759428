import { defineStore } from "pinia";
import { UserState } from "./types";
import { AccountInfo } from "@azure/msal-browser";
import { useUsersApi } from "@/services/api/useUserApi";
import useMsalAccount from "@/hooks/useMsalAccount";
import { User } from "@/custom-types/GeneralTypes";

export const useUserStore = defineStore("user", {
  state: (): UserState => ({
    userAccount: null,
    isAuthenticated: false,
    user: null,
  }),
  getters: {
    hasAcceptedTerms: (state) =>
      state.user?.termsAndConditionsAccepted ?? false,
    isUserLoaded: (state) => state.user !== null,
  },
  actions: {
    setMsalAccount(account: AccountInfo | null) {
      this.userAccount = account;
      this.isAuthenticated = !!account;
    },
    clearUserData() {
      this.userAccount = null;
      this.isAuthenticated = false;
    },
    async fetchLoggedInUser() {
      if (!this.userAccount) {
        return;
      }
      const { getUserInfo } = useUsersApi();
      const { getUserIdFromMsalAccount } = useMsalAccount();
      const userId = getUserIdFromMsalAccount(this.userAccount);
      const userInfoResponse = await getUserInfo(userId);
      const userInfo = userInfoResponse.data;
      this.user = {
        userId: userId,
        email: userInfo.email,
        phoneNumber: userInfo.mobilePhone ?? "",
        name: userInfo.displayName ?? "",
        orgNumbers: userInfo.orgNumber ?? "",
        termsAndConditionsAccepted:
          userInfo?.termsAndConditionsAccepted ?? false,
        newsletterAccepted: userInfo?.newsletterAccepted ?? false,
        otherCommunicationAccepted:
          userInfo?.otherCommunicationAccepted ?? false,
      };
    },
    async updateUser(user: User) {
      this.user = { ...this.user, ...user };
    },
  },
});
