<template>
  <div class="price-request-details">
    <FeedbackNotification
      v-if="shouldShowFeedbackNotification"
      :is-accepted="isOfferAccepted"
      @read-feedback="openFeedbackPanelHandler"
    />
    <div class="price-request-details__grid">
      <div class="price-request-details__item">
        <h3>Referansekode</h3>
        <div class="price-request-details__divider" />
        <p>{{ referenceCode }}</p>
      </div>
      <div class="price-request-details__item">
        <h3>Tjenestekategori</h3>
        <div class="price-request-details__divider" />
        <p>{{ serviceCategory }}</p>
      </div>
      <div class="price-request-details__item">
        <h3>Forespørsel sendt</h3>
        <div class="price-request-details__divider" />
        <p>{{ requestSent }}</p>
      </div>
      <div class="price-request-details__item">
        <h3>Svarfrist</h3>
        <div class="price-request-details__divider" />
        <p>{{ deadlineDate }}</p>
      </div>
      <div class="price-request-details__item">
        <h3>Ønsket oppstartsdato</h3>
        <div class="price-request-details__divider" />
        <p>{{ desiredStartDate }}</p>
      </div>
      <div class="price-request-details__item">
        <h3>Felles befaring</h3>
        <div class="price-request-details__divider" />
        <p v-if="jointInspection">{{ jointInspection }}</p>
      </div>
    </div>

    <div class="price-request-details__full-width">
      <h3>Vilkår</h3>
      <div class="price-request-details__divider" />
      <template v-if="!isLoadingFrameAgreement">
        <attachment
          v-for="attachment in displayedAttachments"
          :key="attachment.fileName"
          :file-name="attachment.fileName"
          :download-handler="attachment.downloadHandler"
          class="price-request-details__attachment"
        />
        <p v-if="displayedAttachments.length === 0">Ingen vedlegg</p>
      </template>
      <div v-else class="price-request-details__spinner-wrapper">
        <b-spinner type="grow" class="price-request-details__loader" />
      </div>
    </div>

    <div class="price-request-details__full-width">
      <h3>Fellesmeldinger</h3>
      <div class="price-request-details__divider" />
      <PublicNotes :tender="tender" :price-request="priceRequest" />
    </div>

    <div class="price-request-details__full-width">
      <h3>
        Arbeidsbeskrivelse
        <button
          class="price-request-details__toggle-btn"
          @click="toggleWorkDescription"
        >
          <img
            :src="isWorkDescriptionTruncated ? ArrowDownIcon : ArrowUpIcon"
          />
          {{ isWorkDescriptionTruncated ? "Vis mer" : "Vis mindre" }}
        </button>
      </h3>
      <div class="price-request-details__divider" />
      <p v-if="truncatedWorkDescription">
        {{ truncatedWorkDescription }}
      </p>
      <p v-else>Ingen beskrivelse av arbeid</p>
    </div>

    <div class="price-request-details__full-width">
      <h3>Vedlegg</h3>
      <div class="price-request-details__divider" />
      <div
        v-if="priceRequestAttachments.length"
        class="price-request-details__attachments"
      >
        <attachment
          v-for="(attachment, index) in priceRequestAttachments"
          :key="index"
          :download-handler="getAttachmentDownloadHandler(attachment)"
          :file-name="attachment.fileName"
          class="price-request-details__attachment"
        />
      </div>
      <div v-else class="price-request-details__no-attachment">
        <p>Ingen vedlegg</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch } from "vue";
import { useRouter } from "vue-router";
import ArrowDownIcon from "@/assets/img/ArrowDown.svg";
import ArrowUpIcon from "@/assets/img/ArrowUp.svg";
import Attachment from "@/components/Attachment/Attachment.vue";
import FeedbackNotification from "@/components/FeedbackNotification/FeedbackNotification.vue";
import useFileDownload from "@/hooks/useFileDownload";
import { useTenderApi } from "@/services/api/useTenderApi";
import { useFileAttachmentApi } from "@/services/api/useFileAttachmentApi";
import {
  FrameAgreement,
  Tender,
  MessageAttachmentDto,
  // ServiceOffer,
} from "@/stores/tender/types";
import {
  LocalAttachment,
  TenderState,
  OfferStatus,
  // PublicActorInfo,
  Feedback,
  RequestState,
} from "@/custom-types/GeneralTypes";
import { useUserStore } from "@/stores/user";
import { TenderArchiveDto } from "@/stores/tender/types";

import PublicNotes from "@/components/PublicNotes/PublicNotes.vue";
import FeedbackPanel from "@/components/FeedbackPanel/FeedbackPanel.vue";
import { useSidePanelStore } from "@/stores/sidePanel/sidePanel";

import { useCms } from "@/composables/useCms";
import { ContentType } from "@/custom-types/CmsContentTypes";
import { RouteNames } from "@/router/types";
import { useStore } from "vuex";
import { findSupplierRequest } from "@/utilities/tenderUtils";

import { useFeedbackCookie } from "@/utilities/useFeedbackCookie";

const { setFeedbackViewedCookie, getFeedbackViewedCookie } =
  useFeedbackCookie();

const store = useStore();

const { fetchCmsContent } = useCms();
const router = useRouter();
const {
  viewAttachmentFile,
  viewFileInNewWindow,
  viewOrderConfirmationDocument,
} = useFileDownload();
const { getAttachment } = useFileAttachmentApi();
const { getFrameAgreement } = useTenderApi();

enum AgreementAndTermsTitles {
  Agreement = "Avtale",
  Terms = "Avtalevilkår",
}

const agreementAndTermsCms = [
  {
    title: AgreementAndTermsTitles.Terms,
    link: "https://cdn.sanity.io/files/0ls0csm4/production/4fbf969e8e608afe6d1452e7d3640b40d32a6cc1.pdf",
  },
  {
    title: AgreementAndTermsTitles.Agreement,
    link: "https://cdn.sanity.io/files/0ls0csm4/production/4fbf969e8e608afe6d1452e7d3640b40d32a6cc1.pdf",
  },
];

interface AcceptanceReason {
  id: number;
  reason: string;
  text: string;
}

interface Bid {
  company: string;
  value: number;
}

interface Props {
  tender: Tender;
  supplierId: number;
  referenceCode: string;
  serviceCategory: string;
  requestSent: string;
  desiredStartDate: string;
  jointInspection?: string;
  sharedMessage: string;
  serviceOfferId: number;
  deadlineDate: string;
  isPublicActor: boolean;
  isOfferAccepted: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  jointInspection: undefined,
});

const STATIC_TENDER_STATES = [
  TenderState.COMPLETED,
  TenderState.OFFERED,
  TenderState.REJECTED,
];

const feedbackViewedKey = computed(() => {
  const baseKey = `feedbackViewed_${props.tender.tenderId}`;
  return STATIC_TENDER_STATES.includes(props.tender.tenderState as TenderState)
    ? baseKey
    : `${baseKey}_${props.tender.tenderState}`;
});

const hasFeedbackBeenViewed = ref(
  getFeedbackViewedCookie(feedbackViewedKey.value),
);

const { updateTenderArchive } = useTenderApi();
const userStore = useUserStore();

const frameAgreement = ref<FrameAgreement | null>(null);
const isWorkDescriptionTruncated = ref(true);
const isLoadingFrameAgreement = ref(true);

const isArchiving = ref(false);

const feedback = ref<Feedback>({
  reasons: [],
  comment: "",
});

const acceptanceReasons = ref<AcceptanceReason[]>([]);

const sidePanelStore = useSidePanelStore();

const maxSharedMessageLengthShort = 100;

const truncatedWorkDescription = computed(() => {
  if (!props.sharedMessage) return "";
  if (props.sharedMessage.length <= maxSharedMessageLengthShort) {
    return props.sharedMessage;
  }
  return isWorkDescriptionTruncated.value
    ? props.sharedMessage.slice(0, maxSharedMessageLengthShort) + "..."
    : props.sharedMessage;
});

const priceRequest = computed(() => props.tender.priceRequests?.[0]);

const priceRequestAttachments = computed(
  () => priceRequest.value?.messageAttachmentDtos || [],
);

const supplierRequest = computed(() =>
  findSupplierRequest(props.tender, props.supplierId),
);

const customerOrganizationId = computed(
  () => props.tender?.customerOrganizationId,
);

const hasFrameAgreement = computed(
  () => supplierRequest.value?.hasFrameAgreement ?? false,
);

const frameAgreementId = computed(
  () => supplierRequest.value?.frameAgreementId || null,
);

const supplierRequestId = computed(() => {
  const supplierRequest = priceRequest.value?.supplierRequests?.find(
    (request) => request.supplierId === props.supplierId,
  );
  return supplierRequest?.id;
});

const frameAgreementAttachments = computed((): LocalAttachment[] => {
  if (isLoadingFrameAgreement.value) {
    return [];
  }
  if (!frameAgreement.value) {
    return [];
  }
  return frameAgreement.value.attachments.map(
    (attachment): LocalAttachment => ({
      fileName: attachment.fileName,
      attachmentUri: attachment.attachmentUri || "",
      downloadHandler: async () => {
        try {
          const response = await getAttachment({
            attachmentId: attachment.attachmentId,
            tenderId: props.tender.tenderId,
            fileName: attachment.fileName,
          });
          await viewFileInNewWindow(response.data, router);
        } catch (error) {
          console.error("Error downloading attachment:", error);
        }
      },
    }),
  );
});

const agreementAndTermsAttachments = computed((): LocalAttachment[] => {
  const today = new Date("2024-12-02");
  const tenderDate = new Date(props.tender.createdOn);

  const documents = agreementAndTermsCms.filter(
    (document) =>
      (document.title !== AgreementAndTermsTitles.Terms ||
        tenderDate >= today) &&
      (props.tender.tenderState === TenderState.ACCEPTED ||
        props.tender.tenderState === TenderState.REJECTED ||
        props.tender.tenderState === TenderState.COMPLETED),
  );

  return documents.map((document) => {
    const isAgreementDocument =
      document.title === AgreementAndTermsTitles.Terms;
    return {
      fileName: document.title,
      attachmentUri: isAgreementDocument ? "" : document.link,
      downloadHandler: isAgreementDocument
        ? async () => {
            try {
              await viewOrderConfirmationDocument(
                props.tender?.tenderId,
                props.serviceOfferId,
                router,
              );
            } catch (error) {
              console.error("Error viewing order confirmation:", error);
            }
          }
        : async () => {
            if (document.link) {
              window.open(document.link, "_blank");
            }
          },
    };
  });
});

const displayedAttachments = computed(() =>
  hasFrameAgreement.value
    ? frameAgreementAttachments.value
    : agreementAndTermsAttachments.value,
);

const fetchFrameAgreement = async () => {
  if (
    hasFrameAgreement.value &&
    frameAgreementId.value &&
    frameAgreementId.value > 0 &&
    customerOrganizationId.value
  ) {
    isLoadingFrameAgreement.value = true;
    try {
      const response = await getFrameAgreement({
        organizationId: customerOrganizationId.value,
        frameAgreementId: frameAgreementId.value,
      });
      frameAgreement.value = response.data || null;
    } catch (err) {
      console.error("Error fetching frame agreement:", err);
      frameAgreement.value = null;
    } finally {
      isLoadingFrameAgreement.value = false;
    }
  } else {
    isLoadingFrameAgreement.value = false;
  }
};

const toggleWorkDescription = () => {
  isWorkDescriptionTruncated.value = !isWorkDescriptionTruncated.value;
};

const getAttachmentDownloadHandler = (attachment: MessageAttachmentDto) => {
  const supplierRequestId = priceRequest.value?.supplierRequests?.[0]?.id;
  if (!supplierRequestId) return;
  return async () => {
    try {
      await viewAttachmentFile(
        {
          attachmentId: attachment.attachmentId,
          tenderId: props.tender.tenderId,
          supplierRequestId: supplierRequestId,
        },
        router,
      );
    } catch (error) {
      console.error("Error viewing attachment:", error);
    }
  };
};

const reasonsWithCmsText = computed(() => {
  return acceptanceReasons.value;
});

const publicActorInfo = computed(() => {
  const isPublicActor = props.isPublicActor;
  if (!isPublicActor) return undefined;

  const { winningBid, otherBids } = extractBidInfo(props.tender);
  return {
    name: props.tender.customerOrganization.name,
    winningBid,
    otherBids,
  };
});

const footerButtons = computed(() => {
  const buttons = [];
  if (!props.isOfferAccepted) {
    buttons.push({
      text: isArchiving.value ? "Arkiverer..." : "Flytt til arkiv",
      onClick: () => {
        moveToArchive();
      },
      variant: "accent",
      shape: "rounded",
      size: "md",
      uppercase: true,
      disabled: isArchiving.value,
    });
  } else {
    buttons.push({
      text: "Se avtale",
      onClick: () => {
        //TODO: Implement the logic to view agreement
        console.log("Viewing agreement");
        sidePanelStore.closePanel();
      },
      variant: "secondary",
      shape: "rounded",
      size: "md",
      uppercase: true,
      disabled: true,
    });
  }
  return buttons;
});

const extractBidInfo = (
  tender: Tender,
): { winningBid: Bid | null; otherBids: Bid[] } => {
  const priceRequest = tender.priceRequests?.[0];
  const supplierRequests = priceRequest?.supplierRequests || [];

  const supplierMap = new Map(
    supplierRequests.map((req) => [req.supplierId, req.supplierName]),
  );

  const createBid = (supplierId: number, value: number): Bid => ({
    company: supplierMap.get(supplierId) || "Unknown Supplier",
    value,
  });

  const winningSupplierRequest = supplierRequests.find(
    (req) => req.requestState === RequestState.ACCEPTED,
  );

  let winningBid: Bid | null = null;
  let otherBids: Bid[] = [];

  tender.supplierPortfolios.forEach((portfolio) => {
    const supplierId = portfolio.supplierId;

    portfolio.serviceTypePackagePortfolio.forEach((pkg) => {
      pkg.serviceOffers.forEach((offer) => {
        const bid = createBid(supplierId, offer.supplierCost);

        if (
          winningSupplierRequest &&
          winningSupplierRequest.supplierId === supplierId &&
          offer.offerState === OfferStatus.ACCEPTED
        ) {
          winningBid = bid;
        } else if (offer.offerState !== OfferStatus.DRAFT) {
          otherBids.push(bid);
        }
      });
    });
  });

  // If no winning bid was found in the portfolios, but we have a winning supplier request
  if (!winningBid && winningSupplierRequest) {
    winningBid = createBid(winningSupplierRequest.supplierId, 0); // Replace 0 with a meaningful default or fetch the actual value if available
  }

  // Remove the winning bid from otherBids if it was accidentally added
  if (winningBid) {
    otherBids = otherBids.filter((bid) => bid.company !== winningBid?.company);
  }

  // Handle case where winning supplier is not in portfolios
  if (!winningBid && winningSupplierRequest) {
    winningBid = createBid(winningSupplierRequest.supplierId, 0); // You might want to replace 0 with a more meaningful value
  }

  return { winningBid, otherBids };
};

const fetchOfferAcceptanceReasonsCms = async () => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response: any = await fetchCmsContent(
      ContentType.OFFER_ACCEPTANCE_REASONS_CMS,
    );

    if (response) {
      const reasonsList = response.pageSections?.find(
        (section: { label: string }) =>
          section.label === "acceptanceReasonsList",
      )?.otherText;
      acceptanceReasons.value =
        reasonsList?.map(
          (item: { label: string; title: string }, index: number) => ({
            id: index,
            reason: item.label,
            text: item.title,
          }),
        ) || [];
    }
  } catch (error) {
    console.error("Error fetching offer acceptance reasons:", error);
  }
};

const acceptanceInfo = computed(() => {
  const supplierRequests =
    props.tender.priceRequests?.[0]?.supplierRequests || [];
  for (const request of supplierRequests) {
    const validAcceptance = request.supplierRequestAcceptances?.find(
      (acceptance) =>
        acceptance.offerAcceptanceReasons.length > 0 || acceptance.comment,
    );
    if (validAcceptance) return validAcceptance;
  }
  return undefined;
});

const openFeedbackPanelHandler = () => {
  if (props.tender.tenderState === TenderState.COMPLETED) return;

  hasFeedbackBeenViewed.value = true;
  setFeedbackViewedCookie(feedbackViewedKey.value, true);

  if (acceptanceInfo.value) {
    const selectedReasons = acceptanceInfo.value?.offerAcceptanceReasons.map(
      (reason) => reason.reason,
    );

    feedback.value = {
      reasons: reasonsWithCmsText.value.filter((reason) =>
        selectedReasons.includes(reason.reason),
      ),
      comment: acceptanceInfo.value.comment || "",
    };
  } else {
    console.warn("No acceptance info found");
    return;
  }

  if (feedback.value.reasons.length > 0 || feedback.value.comment.length > 0) {
    sidePanelStore.openPanel("Tilbakemelding", FeedbackPanel, {
      title: "Tilbakemelding",
      feedback: feedback.value,
      isPublicActor: publicActorInfo.value !== undefined,
      publicActorInfo: publicActorInfo.value,
      isAccepted: props.isOfferAccepted,
      footerButtons: footerButtons.value,
    });
  }
};

const moveToArchive = async () => {
  try {
    if (
      !props.tender?.tenderId ||
      !supplierRequestId.value ||
      !userStore.user?.userId
    ) {
      console.error("Missing required data for archiving");
      return;
    }

    const archiveData: TenderArchiveDto = {
      state: "Archived",
      userId: userStore.user.userId,
      audience: "Supplier",
    };

    const response = await updateTenderArchive({
      tenderId: props.tender.tenderId,
      requestId: supplierRequestId.value,
      flag: "true",
      data: archiveData,
    });

    console.log("Response:", response);

    await store.dispatch("offers/fetchOffers", props.supplierId);

    sidePanelStore.closePanel();

    router.push({
      name: RouteNames.MY_AGREEMENTS,
      query: {
        priceRequestId: props.tender.priceRequests[0]?.id,
      },
    });

    // emit scroll to row
    emit("scroll-to-row");
  } catch (error) {
    console.error("Error moving tender to archive:", error);
    // Optionally, show an error message to the user
  }
};

const shouldShowFeedbackNotification = computed(() => {
  if (!acceptanceInfo.value) return false;
  if (props.tender.tenderState === TenderState.COMPLETED) return false;
  if (supplierRequest.value?.archived) return false;

  const hasFeedbackComment = !!acceptanceInfo.value.comment;

  return hasFeedbackComment && hasFeedbackBeenViewed.value;
});

// Watch for changes in the tender or serviceOfferId and reset the viewed state if they change
watch(
  [() => props.tender.tenderId, () => props.serviceOfferId],
  ([newTenderId, newServiceOfferId], [oldTenderId, oldServiceOfferId]) => {
    if (
      newTenderId !== oldTenderId ||
      newServiceOfferId !== oldServiceOfferId
    ) {
      hasFeedbackBeenViewed.value = getFeedbackViewedCookie(
        feedbackViewedKey.value,
      );
    }
  },
);

onMounted(async () => {
  await fetchFrameAgreement();
  await fetchOfferAcceptanceReasonsCms();

  if (!hasFeedbackBeenViewed.value) {
    await openFeedbackPanelHandler();
  }
});

const emit = defineEmits(["scroll-to-row"]);
</script>

<style lang="scss" scoped>
.price-request-details {
  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px 40px;
    margin-bottom: 40px;
  }

  &__item {
    display: flex;
    flex-direction: column;
  }

  &__full-width {
    margin-bottom: 40px;
  }

  h3 {
    font-size: 16px;
    font-weight: 600;
    color: #1d1d1d;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
  }

  &__divider {
    height: 1px;
    background: #dfcfe5;
    margin-bottom: 8px;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    color: #1d1d1d;
  }

  &__vilkar,
  &__attachment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 14px;
    background: white;
    border: 1px solid #5a5a5a;
    border-radius: 8px;
    width: fit-content;
  }

  &__download-btn {
    width: 24px;
    height: 24px;
    background: none;
    border: none;
    cursor: pointer;
  }

  &__attachments {
    display: flex;
    gap: 4px;
  }

  &__toggle-btn {
    font-size: 14px;
    font-weight: 400;
    color: #1d1d1d;
    text-decoration: underline;
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 8px;

    img {
      width: 24px;
      height: 24px;
      margin-left: 4px;
    }
  }

  .icon-download {
    display: inline-block;
    width: 20px;
    height: 17px;
    background-color: #1d1d1d;
  }
}

@media (max-width: 768px) {
  .price-request-details {
    padding: 24px;

    &__grid {
      grid-template-columns: 1fr;
    }
  }
}
</style>
