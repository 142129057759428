import { watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useUserStore } from "@/stores/user";
import { RouteNames } from "@/router/types";

export function useTermsGuard() {
  const router = useRouter();
  const route = useRoute();
  const userStore = useUserStore();

  watch(
    [() => userStore.isUserLoaded, () => userStore.hasAcceptedTerms],
    ([isLoaded, hasAccepted]) => {
      if (!isLoaded) return;

      const isWelcomePage = route.name === RouteNames.USER_WELCOME;
      const currentFullPath = route.fullPath;

      if (!hasAccepted && !isWelcomePage) {
        const redirectPath = currentFullPath.replace(/^\//, "");

        if (!currentFullPath.startsWith("/welcome")) {
          console.log("pushing to welcome page");
          router.replace({
            name: RouteNames.USER_WELCOME,
            query: { redirect: redirectPath },
          });
        }
      } else if (hasAccepted && isWelcomePage && route.query.redirect) {
        const storedPath = route.query.redirect as string;
        const targetPath = storedPath.startsWith("/")
          ? storedPath
          : `/${storedPath}`;

        if (currentFullPath !== targetPath) {
          router.replace(targetPath);
        }
      }
    },
    { immediate: true },
  );
}
