<template>
  <div class="tender-list-detail-content">
    <template v-if="!isTenderLoading">
      <TenderDetailHeader
        :supplier-id="supplierId"
        :tender="updatedTender"
        :show-give-offer-button="isGiveOfferButtonVisible"
        :show-reject-price-request-button="canRejectPriceRequest"
        :show-mark-as-done-button="isMarkAsDoneButtonVisible"
        :show-create-price-request-button="isCreatePriceRequestButtonVisible"
        @on-create-price-request-click="handleCreatePriceRequestClick"
        @on-price-request-rejected-click="handleRejectPriceRequestClick"
        @on-mark-as-done-click="handleMarkAsDoneClick"
        @on-new-offer-click="handleNewOfferButtonClick"
      >
        <template #tabs>
          <TenderDetailTabs
            :tabs="currentTabs"
            :active-tab-label="activeTabLabel"
            @tab-change="handleClickedTab"
          />
        </template>
        <template #agreementResponsible>
          <AgreementResponsible
            :supplier-id="supplierId"
            :tender="updatedTender"
          />
        </template>
      </TenderDetailHeader>

      <div class="tender-detail-content">
        <component
          :is="tabComponents.get(activeTabLabel) || PriceRequestDetails"
          v-if="updatedTender"
          v-bind="getComponentProps(activeTabLabel)"
          @scroll-to-row="handleScrollToRow"
        />
      </div>

      <reject-price-request-modal
        v-model="showRejectPriceRequestModal"
        :tender-id="updatedTender.tenderId"
        :price-request-id="priceRequestId"
        :supplier-request-id="supplierRequestId"
        :user-id="userId"
        :supplier-id="supplierId"
        @price-request-rejected="onPriceRejected"
      />

      <reject-price-request-success-modal
        v-model="showRejectPriceRequestSuccessModal"
        @primary-click="handleOnPriceRejectedSuccess"
      />

      <complete-tender-offer-modal
        v-model="showCompleteTenderOfferModal"
        :tender-id="updatedTender.tenderId"
        :service-offer-id="selectedServiceOfferId"
        @completed="onOfferCompleted"
      />

      <create-price-request-modal
        v-model="showCreatePriceRequestModal"
        :supplier-service-types="supplierServiceTypes"
        :supplier-users="supplierUsers"
        :tender="updatedTender"
        :supplier-id="supplierId"
      />

      <LateOfferModal
        ref="lateOfferModal"
        :tender-id="updatedTender?.tenderId"
        @ok="handleLateOfferOk"
      />
    </template>
    <div v-else class="tender-list-detail-content__spinner-wrapper">
      <b-spinner class="tender-list-detail-content__loader" type="grow" small />
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  ref,
  computed,
  onMounted,
  watch,
  DefineComponent,
  onBeforeUnmount,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

import MessagesWrapper from "@/components/MessagesWrapper/MessagesWrapper.vue";
import ServiceReportWrapper from "@/components/ServiceReport/ServiceReportWrapper.vue";
import PriceRequestDetails from "@/components/PriceRequestDetails/PriceRequestDetails.vue";
import SupplierOffersWrapper from "@/components/SupplierOffersWrapper/SupplierOffersWrapper.vue";
import AgreementWrapper from "@/components/AgreementWrapper/AgreementWrapper.vue";
import EconomyTab from "@/components/Economy/EconomyTab.vue";

import TenderDetailHeader from "./TenderDetailHeader.vue";
import TenderDetailTabs from "./TenderDetailTabs.vue";
import AgreementResponsible from "@/components/AgreementResponsible/AgreementResponsible.vue";

import RejectPriceRequestModal from "@/components/RejectPriceRequestModal/RejectPriceRequestModal.vue";
import RejectPriceRequestSuccessModal from "@/components/RejectPriceRequestSuccessModal/RejectPriceRequestSuccessModal.vue";
import CreatePriceRequestModal from "@/components/CreatePriceRequestModal/CreatePriceRequestModal.vue";
import CompleteTenderOfferModal from "../CompleteTenderOfferModal/CompleteTenderOfferModal.vue";
import LateOfferModal from "@/components/LateOfferModal/LateOfferModal.vue";

import {
  RequestState,
  TenderState,
  TenderViewTabLabels,
  JobType,
  OfferStatus,
  Feedback,
} from "@/custom-types/GeneralTypes";

import {
  isTenderSingleJob,
  getCustomerWantedStartDate,
  getPriceRequestSentDate,
  getSurveyDateTime,
  getPriceRequestMessage,
} from "@/utilities/offerUtils";

import { formatToLongDateString } from "@/utilities/dateUtils";
import { useSupplierStore } from "@/stores/supplier";
import { useTenderApi } from "@/services/api/useTenderApi";
import { useSupplierApi } from "@/services/api/useSupplierApi";
import { useCmsStore } from "@/stores/cms";
import { useUserStore } from "@/stores/user";
import type {
  Tender,
  ServiceTypePackagePortfolio,
  SupplierRequest,
  PriceRequest,
} from "@/stores/tender/types";
import type { SupplierUser } from "@/stores/supplierUsers/types";
import { ServiceType } from "@/stores/supplier/types";
import { ServiceCategory } from "@/stores/supplier/types";
import { RouteNames } from "@/router/types";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { addBusinessDays } from "@/utilities/dateUtils";
import FeedbackPanel from "@/components/FeedbackPanel/FeedbackPanel.vue";

import { useCms } from "@/composables/useCms";
import { ContentType } from "@/custom-types/CmsContentTypes";
import { useSidePanelStore } from "@/stores/sidePanel/sidePanel";
import { findSupplierRequest } from "@/utilities/tenderUtils";
import { useFeedbackCookie } from "@/utilities/useFeedbackCookie";

const { setFeedbackViewedCookie, getFeedbackViewedCookie } =
  useFeedbackCookie();

const { fetchCmsContent } = useCms();
dayjs.extend(utc);

interface AcceptanceReason {
  id: number;
  reason: string;
  text: string;
}

interface Bid {
  company: string;
  value: number;
}

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;
const TIMEOUT = 30000;

const VALID_STATES = [TenderState.ACCEPTED, TenderState.COMPLETED];

export interface ServiceCategoryBase {
  id?: number;
  categoryLabel: string;
}

export interface ServiceCategoryWithOnlyIdAndCategoryLabel
  extends ServiceCategoryBase {
  serviceCategoryId?: number;
}

interface FormattedServiceType {
  id: number;
  title: string;
  label: string;
  categories: FormattedCategory[];
}

interface FormattedCategory {
  title: string;
  label: string;
  id?: number;
}

interface CmsServiceType {
  label: string;
  name?: string;
  serviceCategories?: { label: string; title?: string }[];
}

const props = defineProps<{
  tender: Tender;
  supplierId: number;
  supplierUsers: SupplierUser[] | null;
}>();

const router = useRouter();
const route = useRoute();
const supplierStore = useSupplierStore();
const cmsStore = useCmsStore();
const userStore = useUserStore();
const store = useStore();

const { getSupplierServiceCategories } = useSupplierApi();
const { getTender, getActivityFormFrequencyOptions } = useTenderApi();

const STATIC_TENDER_STATES = [
  TenderState.COMPLETED,
  TenderState.OFFERED,
  TenderState.REJECTED,
];

const feedbackViewedKey = computed(() => {
  const baseKey = `feedbackViewed_${props.tender.tenderId}`;
  return STATIC_TENDER_STATES.includes(props.tender.tenderState as TenderState)
    ? baseKey
    : `${baseKey}_${props.tender.tenderState}`;
});

const hasFeedbackBeenViewed = ref(
  getFeedbackViewedCookie(feedbackViewedKey.value),
);

const feedback = ref<Feedback>({
  reasons: [],
  comment: "",
});

const acceptanceReasons = ref<AcceptanceReason[]>([]);

const sidePanelStore = useSidePanelStore();

const showRejectPriceRequestModal = ref(false);
const showRejectPriceRequestSuccessModal = ref(false);
const showCreatePriceRequestModal = ref(false);
const showCompleteTenderOfferModal = ref(false);
const frequencyOptions = ref<string[] | null>(null);
const supplierServiceTypes = ref<FormattedServiceType[]>([]);
const updatedTender = ref<Tender>({} as Tender);
const isTenderLoading = ref(true);
const lateOfferModal = ref<InstanceType<typeof LateOfferModal> | null>(null);

const bookingNumber = computed(() => {
  if (updatedTender.value?.tenderState !== TenderState.ACCEPTED) return null;

  const bookingNumber = updatedTender.value?.acceptedOfferReferences?.[0];
  return bookingNumber || null;
});

const supplierOfferWasAccepted = computed(() => {
  const serviceOffer = updatedTender.value?.supplierPortfolios
    ?.filter((portfolio) => portfolio.supplierId === props.supplierId)
    ?.flatMap((portfolio) => portfolio.serviceTypePackagePortfolio || [])
    .flatMap((pkg) => pkg?.serviceOffers || [])
    .find((offer) => offer.id === selectedServiceOfferId.value);

  return (
    serviceOffer?.offerState === OfferStatus.ACCEPTED ||
    serviceOffer?.offerState === OfferStatus.COMPLETED
  );
});

const reasonsWithCmsText = computed(() => {
  return acceptanceReasons.value;
});

const publicActorInfo = computed(() => {
  const isPublicActor = props.tender.publicActor;
  if (!isPublicActor) return undefined;

  const { winningBid, otherBids } = extractBidInfo(updatedTender.value);
  return {
    name: updatedTender.value.customerOrganization.name,
    winningBid,
    otherBids,
  };
});

const footerButtons = computed(() => {
  const buttons = [];
  if (!supplierOfferWasAccepted.value) {
    buttons.push({
      text: "Ok",
      onClick: () => {
        console.log("Moving to archive");
        sidePanelStore.closePanel();
      },
      variant: "accent",
      shape: "rounded",
      size: "md",
      uppercase: true,
    });
  } else {
    buttons.push({
      text: "Se avtale",
      onClick: () => {
        sidePanelStore.closePanel();
      },
      variant: "secondary",
      shape: "rounded",
      size: "md",
      uppercase: true,
    });
  }
  return buttons;
});

// data for PriceRequestDetails props
const referenceCode = computed(
  () => `#${updatedTender.value?.externalTenderId}`,
);

const serviceCategory = computed(() => {
  const { matchedServiceType, matchedServiceCategory } =
    getServiceTypeAndServiceCategory();
  const jobType = isTenderSingleJob(updatedTender.value, props.supplierId)
    ? JobType.SINGLE
    : JobType.REPEATING;

  return matchedServiceCategory?.title
    ? `${matchedServiceType?.name} - ${matchedServiceCategory.title} (${jobType})`
    : "";
});

const desiredStartDate = computed(() => {
  return formatToLongDateString(
    getCustomerWantedStartDate(updatedTender.value),
  );
});

const requestSent = computed(() => {
  return formatToLongDateString(getPriceRequestSentDate(updatedTender.value));
});

const jointInspection = computed(() => {
  const dateTime = getSurveyDateTime(updatedTender.value);
  return dateTime
    ? formatToLongDateString(dateTime) +
        " kl. " +
        dayjs(dateTime).format("HH:mm")
    : "";
});

const sharedMessage = computed(() => {
  const sharedMessage = getPriceRequestMessage(updatedTender.value);
  return sharedMessage ? sharedMessage : "Ingen delte meldinger for øyeblikket";
});
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TabComponent = DefineComponent<any, any, any>;

const tabComponents = new Map<string, TabComponent>([
  [TenderViewTabLabels.priceRequest, PriceRequestDetails as TabComponent],
  [TenderViewTabLabels.messages, MessagesWrapper as TabComponent],
  [TenderViewTabLabels.agreement, AgreementWrapper as TabComponent],
  [TenderViewTabLabels.offers, SupplierOffersWrapper as TabComponent],
  [TenderViewTabLabels.documentation, ServiceReportWrapper as TabComponent],
  [TenderViewTabLabels.economy, EconomyTab as TabComponent],
]);

const getComponentProps = (tabLabel: string) => {
  const baseProps = {
    tender: updatedTender.value,
    supplierId: props.supplierId,
    serviceOfferId: selectedServiceOfferId.value,
  };

  switch (tabLabel) {
    case TenderViewTabLabels.priceRequest:
      return {
        ...baseProps,
        referenceCode: referenceCode.value,
        serviceCategory: serviceCategory.value,
        requestSent: requestSent.value,
        desiredStartDate: desiredStartDate.value,
        jointInspection: jointInspection.value,
        serviceOfferId: selectedServiceOfferId.value,
        sharedMessage: sharedMessage.value,
        deadlineDate: responseDeadlineDate.value,
        isPublicActor: props.tender.publicActor,
        isOfferAccepted: supplierOfferWasAccepted.value,
      };
    case TenderViewTabLabels.messages:
      return baseProps;
    case TenderViewTabLabels.agreement:
      return baseProps;
    case TenderViewTabLabels.offers:
      return {
        ...baseProps,
        frequencyOptions: frequencyOptions.value,
        isGiveOfferButtonVisible: isGiveOfferButtonVisible.value,
      };
    case TenderViewTabLabels.documentation:
      return baseProps;
    case TenderViewTabLabels.economy:
      return { ...baseProps, bookingNumber: bookingNumber.value };
    default:
      return baseProps;
  }
};

const currentTabs = computed(() => {
  const tabs = {
    priceRequest: {
      label: TenderViewTabLabels.priceRequest,
      title: "Prisforespørsel",
    },
    messages: { label: TenderViewTabLabels.messages, title: "Meldinger" },
    offers: { label: TenderViewTabLabels.offers, title: "Gi tilbud" },
    agreement: { label: TenderViewTabLabels.agreement, title: "Avtale" },
    documentation: {
      label: TenderViewTabLabels.documentation,
      title: "Dokumentasjon på utført arbeid",
    },
    economy: { label: TenderViewTabLabels.economy, title: "Økonomi" },
  };
  return isActiveAgreement.value
    ? [
        tabs.messages,
        tabs.agreement,
        tabs.documentation,
        tabs.economy,
        tabs.priceRequest,
      ]
    : [tabs.priceRequest, tabs.messages, tabs.offers];
});

const activeTabLabel = computed(() => {
  const routeLabel = route.query.tab as string;
  const tabLabelValues = currentTabs.value.map((tab) => tab.label);
  return tabLabelValues.includes(routeLabel)
    ? routeLabel
    : tabLabelValues[0] ?? TenderViewTabLabels.priceRequest;
});

const responseDeadlineDate = computed(() => {
  const deadlineDate = getTenderResponseDeadlineDate(updatedTender.value);
  return deadlineDate
    ? dayjs(deadlineDate).format("DD. MMMM YYYY")
    : "Ikke satt";
});

const priceRequestId = computed(() => {
  const priceRequest = updatedTender.value?.priceRequests[0];
  return priceRequest?.id ?? null;
});

const supplierRequestId = computed(() => {
  const supplierRequest = findSupplierRequest(
    updatedTender.value,
    props.supplierId,
  );
  return supplierRequest?.id ?? null;
});

const selectedServiceOfferId = computed(() => {
  if (!isTenderAvailable.value) return 0;
  const serviceTypePackagePortfolio = findSelectedServiceTypePackagePortfolio();
  for (const serviceOffer of serviceTypePackagePortfolio?.serviceOffers || []) {
    const aggregatedPrice = serviceOffer.serviceOfferPrices.find(
      (price) => price.priceType === "Aggregated",
    );
    if (aggregatedPrice) return aggregatedPrice.serviceOfferId;
  }
  return 0;
});

const isActiveAgreement = computed(() => {
  const supplierRequest = findSupplierRequest(
    updatedTender.value,
    props.supplierId,
  );
  return (
    supplierRequest?.requestState === TenderState.ACCEPTED ||
    supplierRequest?.requestState === TenderState.COMPLETED
  );
});

const hasActiveAgreement = computed(() => {
  if (!isTenderAvailable.value) return false;

  const supplierRequests =
    updatedTender.value?.priceRequests?.[0]?.supplierRequests || [];
  return supplierRequests.some(
    (request) =>
      request.supplierId !== props.supplierId &&
      request.requestState === TenderState.ACCEPTED,
  );
});

const isGiveOfferButtonVisible = computed(() => {
  if (!isTenderAvailable.value) return false;
  return !isActiveAgreement.value && !hasActiveAgreement.value;
});

const isCreatePriceRequestButtonVisible = computed(() => {
  if (!isTenderAvailable.value) return false;
  return (
    !isGiveOfferButtonVisible.value &&
    isActiveAgreement.value &&
    !hasActiveAgreement.value
  );
});

const canRejectPriceRequest = computed(() => {
  if (!props.tender) return false;

  const isRequestAlreadyRejected = isRequestRejected();
  const isTenderStateValid = VALID_STATES.includes(
    props.tender.tenderState as TenderState,
  );

  return !isRequestAlreadyRejected && !isTenderStateValid;
});

const isRequestRejected = (): boolean => {
  const supplierRequest = findSupplierRequest(
    updatedTender.value,
    props.supplierId,
  );
  return (
    supplierRequest?.requestState === RequestState.REJECTED ||
    supplierRequest?.requestState === RequestState.ARCHIVED
  );
};

const isMarkAsDoneButtonVisible = computed(() => {
  if (!isTenderAvailable.value) return false;
  if (isTenderCompleted.value) return false;
  if (!isActiveAgreement.value) return false;
  return isTenderSingleJob(updatedTender.value, props.supplierId);
});

const isTenderAvailable = computed(
  () => !isTenderLoading.value && isUpdatedTenderPopulated(),
);

const isTenderCompleted = computed(
  () => updatedTender.value?.tenderState === TenderState.COMPLETED,
);

const userId = computed(() => userStore.user?.userId);

const handleLateOfferOk = () => {
  router.push({
    name: RouteNames.CREATE_OFFER,
    params: { tenderId: props.tender.tenderId },
  });
};

const getServiceTypeAndServiceCategory = () => {
  const { priceRequests } = updatedTender.value;
  const serviceCategoryLabel = priceRequests[0]?.serviceCategoryLabel;
  if (!serviceCategoryLabel) return {};
  const serviceTypesCms = cmsStore[
    ContentType.SERVICE_TYPES_CMS
  ] as CmsServiceType[];
  const matchedServiceType = findMatchingCmsServiceType(
    serviceTypesCms,
    serviceCategoryLabel,
  );
  if (!matchedServiceType) return {};
  const matchedServiceCategory = findMatchingServiceCategory(
    matchedServiceType,
    serviceCategoryLabel,
  );

  return { matchedServiceType, matchedServiceCategory };
};

const isUpdatedTenderPopulated = (): boolean => {
  return Object.keys(updatedTender.value).length > 0;
};

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const controller = ref<AbortController | null>(null);
const isComponentMounted = ref(false);

const fetchTenderDetails = async (): Promise<void> => {
  let attempts = 0;

  while (attempts < MAX_RETRIES && isComponentMounted.value) {
    try {
      controller.value = new AbortController();
      const timeoutId = setTimeout(() => {
        if (controller.value) controller.value.abort();
      }, TIMEOUT);

      const response = await getTender(
        {
          supplierId: props.supplierId,
          tenderId: props.tender.tenderId,
        },
        { signal: controller.value.signal },
      );

      clearTimeout(timeoutId);
      if (isComponentMounted.value) {
        updatedTender.value = response.data;
      }
      return;
    } catch (error: unknown) {
      if (!isComponentMounted.value) return;

      attempts++;

      // Type guard for error object
      if (
        error &&
        typeof error === "object" &&
        "name" in error &&
        error.name === "CanceledError"
      ) {
        return;
      }

      const shouldRetry =
        attempts < MAX_RETRIES &&
        error instanceof Error &&
        (error.message.includes("timeout") ||
          error.message.includes("network error"));

      if (shouldRetry) {
        console.warn(
          `Attempt ${attempts} failed, retrying in ${RETRY_DELAY}ms...`,
        );
        await sleep(RETRY_DELAY);
        continue;
      }

      console.error("Error fetching tender details:", error);
      throw error;
    } finally {
      isTenderLoading.value = false;
    }
  }
};

const fetchActivityFormFrequencyOptions = async (): Promise<void> => {
  try {
    const response = await getActivityFormFrequencyOptions();
    frequencyOptions.value = response.data;
  } catch (error) {
    console.error("Error fetching activity form frequency options:", error);
    frequencyOptions.value = null;
  }
};

const fetchServiceCategories = async () => {
  try {
    const response = await getSupplierServiceCategories({
      supplierId: props.supplierId,
    });
    const categories = response.data;
    const cms = cmsStore[ContentType.SERVICE_TYPES_CMS] as CmsServiceType[];
    const serviceTypes = supplierStore.serviceTypes;
    supplierServiceTypes.value = formatServiceTypeCategories(
      categories,
      cms,
      serviceTypes,
    );
  } catch (error) {
    console.error("Error fetching service categories:", error);
  }
};

const handleClickedTab = (tab: { label: string }): void => {
  if (tab?.label) {
    router.replace({
      name: route.name as string,
      query: { ...route.query, tab: tab.label },
    });
  } else {
    console.error("Invalid tab parameter:", tab);
  }
};

const handleNewOfferButtonClick = (): void => {
  if (updatedTender.value?.tenderId) {
    const daysToDeadline = getDaysToDeadline(updatedTender.value);

    if (daysToDeadline !== undefined && daysToDeadline <= 0) {
      lateOfferModal.value?.open();
    } else {
      router.push({
        name: RouteNames.CREATE_OFFER,
        params: { tenderId: updatedTender.value.tenderId },
      });
    }
  } else {
    console.error("Invalid tenderId:", updatedTender.value?.tenderId);
  }
};

const getDaysToDeadline = (tender: Tender): number | undefined => {
  const deadlineDate = dayjs.utc(getTenderResponseDeadlineDate(tender));
  const now = dayjs.utc();

  if (!deadlineDate.isValid()) {
    console.warn(`Invalid deadline date for tender ${tender.tenderId}`);
    return undefined;
  }

  return Math.ceil(deadlineDate.diff(now, "day", true));
};

const refreshTenders = async (): Promise<void> => {
  const supplier = store.getters["supplier/getSupplier"];
  if (supplier?.id) {
    await store.dispatch("offers/fetchOffers", supplier.id);
    await fetchTenderDetails();
    router.push({ name: RouteNames.MY_AGREEMENTS }).then(() => {
      // Wait for DOM to update
      setTimeout(() => {
        const titles = document.getElementsByClassName(
          "agreements-list__title",
        );
        if (titles.length >= 2) {
          const offset = 200;
          const topPosition = titles[1]?.getBoundingClientRect()?.top;
          if (topPosition !== undefined) {
            window.scrollTo({
              top: topPosition + window.scrollY - offset,
              behavior: "smooth",
            });
          }
        }
      }, 100);
    });
  }
};

const onOfferCompleted = async (): Promise<void> => {
  showCompleteTenderOfferModal.value = false;
  await refreshTenders();
};

const handleOnPriceRejectedSuccess = async (): Promise<void> => {
  await refreshTenders();
};

const onPriceRejected = (): void => {
  showRejectPriceRequestModal.value = false;
  showRejectPriceRequestSuccessModal.value = true;
};

const handleCreatePriceRequestClick = (): void => {
  showCreatePriceRequestModal.value = true;
};

const handleRejectPriceRequestClick = (): void => {
  showRejectPriceRequestModal.value = true;
};

const handleMarkAsDoneClick = (): void => {
  showCompleteTenderOfferModal.value = true;
};

const findSelectedServiceTypePackagePortfolio =
  (): ServiceTypePackagePortfolio | null => {
    const { supplierPortfolios } = updatedTender.value || {};
    if (!supplierPortfolios?.length) return null;

    for (const supplierPortfolio of supplierPortfolios) {
      const { serviceTypePackagePortfolio } = supplierPortfolio;
      if (Array.isArray(serviceTypePackagePortfolio)) {
        const selectedPortfolio = serviceTypePackagePortfolio.find(
          (portfolio) => isServiceTypePackagePortfolioSelected(portfolio),
        );
        if (selectedPortfolio) return selectedPortfolio;
      }
    }
    return null;
  };

const isServiceTypePackagePortfolioSelected = (
  serviceTypePackagePortfolio: ServiceTypePackagePortfolio,
): boolean => {
  const { offerStatus } = serviceTypePackagePortfolio || {};
  return (
    offerStatus === OfferStatus.ACCEPTED ||
    offerStatus === OfferStatus.COMPLETED
  );
};

const isValidPriceRequest = (pr: PriceRequest): boolean =>
  pr &&
  typeof pr.offerResponsePeriodInDays === "number" &&
  pr.offerResponsePeriodInDays > 0;

const getValidRequestDates = (
  supplierRequests: SupplierRequest[] = [],
): dayjs.Dayjs[] =>
  supplierRequests
    .flatMap((request) => request.requestStateChanges)
    .filter((change) => change.stateTo === RequestState.PRICE_REQUESTED)
    .map((change) => dayjs(change.createdOn))
    .filter((date) => date.isValid());

const getTenderResponseDeadlineDate = (tender: Tender): Date | undefined => {
  if (!tender?.priceRequests?.length) return undefined;

  const priceRequest = tender.priceRequests[0];
  if (!priceRequest) return undefined;
  if (!isValidPriceRequest(priceRequest)) return undefined;

  const allValidDates = [
    ...getValidRequestDates(priceRequest.supplierRequests),
    dayjs(tender.createdOn),
  ].filter((date) => date.isValid());

  const latestDate = allValidDates.reduce((latest, current) =>
    current.isAfter(latest) ? current : latest,
  );

  return allValidDates.length
    ? addBusinessDays(
        latestDate.toDate(),
        priceRequest.offerResponsePeriodInDays,
      )
    : undefined;
};

// Utility functions
const findMatchingCmsServiceType = (
  cms: CmsServiceType[],
  categoryLabel: string,
) =>
  cms.find((serviceType) =>
    serviceType.serviceCategories?.some(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (cat: any) => cat.label === categoryLabel,
    ),
  );

const findMatchingServiceType = (
  serviceTypes: ServiceType[],
  cmsServiceType: CmsServiceType,
) =>
  serviceTypes.find(
    (serviceType) => serviceType.sysLabel === cmsServiceType.label,
  );

const findMatchingServiceCategory = (
  cmsServiceType: CmsServiceType,
  categoryLabel: string,
) =>
  cmsServiceType.serviceCategories?.find(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (cat: any) => cat.label === categoryLabel,
  );

const logWarning = (message: string) => console.warn(message);

// Main function
const formatServiceTypeCategories = (
  categories: ServiceCategory[],
  cms: CmsServiceType[],
  serviceTypes: ServiceType[],
): FormattedServiceType[] => {
  if (!cms || !serviceTypes) {
    logWarning("CMS or serviceTypes is missing");
    return [];
  }

  const formattedServiceTypes = categories.reduce(
    (acc, category) => {
      const { categoryLabel, id } = category;
      const matchedCmsServiceType = findMatchingCmsServiceType(
        cms,
        categoryLabel,
      );

      if (!matchedCmsServiceType) {
        logWarning(
          `No matching CMS service type found for category ${categoryLabel}`,
        );
        return acc;
      }

      const supplierTypeLabel = matchedCmsServiceType.label;
      const matchedServiceType = findMatchingServiceType(
        serviceTypes,
        matchedCmsServiceType,
      );

      if (!matchedServiceType) {
        logWarning(
          `No matching supplier type found for label ${matchedCmsServiceType.label}`,
        );
        return acc;
      }

      if (!acc[supplierTypeLabel]) {
        acc[supplierTypeLabel] = createFormattedServiceType(
          matchedServiceType,
          matchedCmsServiceType,
        );
      }

      const formattedCategory = createFormattedCategory(
        matchedCmsServiceType,
        categoryLabel,
        id,
      );
      acc[supplierTypeLabel].categories.push(formattedCategory);

      return acc;
    },
    {} as Record<string, FormattedServiceType>,
  );

  return Object.values(formattedServiceTypes);
};

const createFormattedServiceType = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  matchedServiceType: any,
  matchedCmsServiceType: CmsServiceType,
): FormattedServiceType => ({
  id: matchedServiceType.id,
  title: matchedCmsServiceType.name || matchedCmsServiceType.label,
  label: matchedCmsServiceType.label,
  categories: [],
});

const createFormattedCategory = (
  matchedCmsServiceType: CmsServiceType,
  categoryLabel: string,
  id?: number,
): FormattedCategory => {
  const matchedServiceCategory = findMatchingServiceCategory(
    matchedCmsServiceType,
    categoryLabel,
  );

  if (!matchedServiceCategory) {
    logWarning(
      `No matching service category found for ${categoryLabel} in ${matchedCmsServiceType.label}`,
    );
  }

  return {
    title: matchedServiceCategory?.title || categoryLabel,
    label: categoryLabel,
    id,
  };
};

const extractBidInfo = (
  tender: Tender,
): { winningBid: Bid | null; otherBids: Bid[] } => {
  const priceRequest = tender.priceRequests?.[0];
  const supplierRequests = priceRequest?.supplierRequests || [];

  const supplierMap = new Map(
    supplierRequests.map((req) => [req.supplierId, req.supplierName]),
  );

  const createBid = (supplierId: number, value: number): Bid => ({
    company: supplierMap.get(supplierId) || "Unknown Supplier",
    value,
  });

  const winningSupplierRequest = supplierRequests.find(
    (req) => req.requestState === RequestState.ACCEPTED,
  );

  let winningBid: Bid | null = null;
  let otherBids: Bid[] = [];

  tender.supplierPortfolios.forEach((portfolio) => {
    const supplierId = portfolio.supplierId;

    portfolio.serviceTypePackagePortfolio.forEach((pkg) => {
      pkg.serviceOffers.forEach((offer) => {
        const bid = createBid(supplierId, offer.supplierCost);

        if (
          winningSupplierRequest &&
          winningSupplierRequest.supplierId === supplierId &&
          offer.offerState === OfferStatus.ACCEPTED
        ) {
          winningBid = bid;
        } else if (offer.offerState !== OfferStatus.DRAFT) {
          otherBids.push(bid);
        }
      });
    });
  });

  // If no winning bid was found in the portfolios, but we have a winning supplier request
  if (!winningBid && winningSupplierRequest) {
    winningBid = createBid(winningSupplierRequest.supplierId, 0); // Replace 0 with a meaningful default or fetch the actual value if available
  }

  // Remove the winning bid from otherBids if it was accidentally added
  if (winningBid) {
    otherBids = otherBids.filter((bid) => bid.company !== winningBid?.company);
  }

  // Handle case where winning supplier is not in portfolios
  if (!winningBid && winningSupplierRequest) {
    winningBid = createBid(winningSupplierRequest.supplierId, 0); // You might want to replace 0 with a more meaningful value
  }

  return { winningBid, otherBids };
};

const fetchOfferAcceptanceReasonsCms = async () => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response: any = await fetchCmsContent(
      ContentType.OFFER_ACCEPTANCE_REASONS_CMS,
    );

    if (response) {
      const reasonsList = response.pageSections?.find(
        (section: { label: string }) =>
          section.label === "acceptanceReasonsList",
      )?.otherText;
      acceptanceReasons.value =
        reasonsList?.map(
          (item: { label: string; title: string }, index: number) => ({
            id: index,
            reason: item.label,
            text: item.title,
          }),
        ) || [];
    }
  } catch (error) {
    console.error("Error fetching offer acceptance reasons:", error);
  }
};

const openFeedbackPanelHandler = () => {
  if (props.tender.tenderState === TenderState.COMPLETED) return;

  hasFeedbackBeenViewed.value = true;
  setFeedbackViewedCookie(feedbackViewedKey.value, true);

  const acceptanceInfo = findSupplierRequest(
    updatedTender.value,
    props.supplierId,
  )?.supplierRequestAcceptances?.[0];

  if (acceptanceInfo) {
    const selectedReasons = acceptanceInfo?.offerAcceptanceReasons.map(
      (reason) => reason.reason,
    );

    feedback.value = {
      reasons: reasonsWithCmsText.value.filter((reason) =>
        selectedReasons.includes(reason.reason),
      ),
      comment: acceptanceInfo.comment || "",
    };
  } else {
    console.warn("No acceptance info found");
    return;
  }

  if (feedback.value.reasons.length > 0 || feedback.value.comment.length > 0) {
    sidePanelStore.openPanel("Tilbakemelding", FeedbackPanel, {
      title: "Tilbakemelding",
      feedback: feedback.value,
      isPublicActor: publicActorInfo.value !== undefined,
      publicActorInfo: publicActorInfo.value,
      isAccepted: supplierOfferWasAccepted.value,
      footerButtons: footerButtons.value,
    });
  }
};

const emit = defineEmits(["scroll-to-row"]);

const handleScrollToRow = () => {
  emit("scroll-to-row");
};

watch(
  () => supplierStore.supplier,
  () => {
    if (isActiveAgreement.value) {
      fetchServiceCategories();
    }
  },
  { immediate: true, deep: true },
);

// Watch for changes in the tender or serviceOfferId and reset the viewed state if they change
watch(
  [() => updatedTender.value?.tenderId, () => selectedServiceOfferId.value],
  ([newTenderId, newServiceOfferId], [oldTenderId, oldServiceOfferId]) => {
    if (
      newTenderId !== oldTenderId ||
      newServiceOfferId !== oldServiceOfferId
    ) {
      hasFeedbackBeenViewed.value = getFeedbackViewedCookie(
        feedbackViewedKey.value,
      );
    }
  },
);

onMounted(async () => {
  isComponentMounted.value = true;
  try {
    await fetchTenderDetails();
    if (!isComponentMounted.value) return;

    await fetchActivityFormFrequencyOptions();
    if (!isComponentMounted.value) return;

    if (isActiveAgreement.value) {
      await fetchServiceCategories();
      if (!isComponentMounted.value) return;
    }

    await fetchOfferAcceptanceReasonsCms();
    if (!isComponentMounted.value) return;

    if (!hasFeedbackBeenViewed.value) {
      await openFeedbackPanelHandler();
    }
  } catch (error) {
    if (error instanceof Error && error.name !== "CanceledError") {
      console.error("Error in onMounted hook:", error);
    }
  }
});

onBeforeUnmount(() => {
  isComponentMounted.value = false;
  if (controller.value) {
    controller.value.abort();
  }
});
</script>

<style lang="scss" scoped>
.tender-list-detail-content {
  min-height: 30rem;

  &__spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    color: $color-primary;
    margin: auto;
  }
}

.tender-detail-content {
  background-color: #fff;
  padding: 2rem 4rem;
  cursor: default;
}
</style>
