import { computed, reactive } from "vue";
import { useRouter } from "vue-router";
import { RouteNames } from "@/router/types";
import { FileRecord, getFileUrl } from "@/utilities/cmsUtils";
import { formatCurrency } from "@/utilities/numberUtils";
import { BillingCycleUnits } from "@/custom-types/GeneralTypes";
import { ContentType } from "@/custom-types/CmsContentTypes";
import { Tender, ServiceOffer, FrameAgreement } from "@/stores/tender/types";
import useFileDownload from "@/hooks/useFileDownload";
import { useFileAttachmentApi } from "@/services/api/useFileAttachmentApi";
import { useTenderApi } from "@/services/api/useTenderApi";
import { useCms } from "@/composables/useCms";
import { useUsersApi } from "@/services/api/useUserApi";
import { formatDateToDotSeparated } from "@/utilities/dateUtils";
import { OfferStatus } from "@/custom-types/GeneralTypes";
import type { UserDTO } from "@/custom-types/GeneralTypes";

// Types
interface State {
  termsAndConditionsCms: TermsAndConditionsPageCms | null;
  frameAgreement: FrameAgreement | null;
  isLoadingFrameAgreement: boolean;
  offeredByUser: UserDTO | null;
  acceptedByUser: UserDTO | null;
}

interface ServiceType {
  label: string;
  serviceCategories: ServiceCategory[];
}

interface ServiceCategory {
  label: string;
  title: string;
}

interface ExtendedBillableProps {
  tender: Tender;
  serviceOffers: ServiceOffer[];
  serviceTypesCms: ServiceType[];
  supplierId: number;
}

interface TermsAndConditionsPageCms {
  filedoc: FileRecord[];
}

// Constants
const BUTTON_LABELS = {
  VIEW_DETAILS: "SE DETALJER",
  DOWNLOAD_AGREEMENT: "LAST NED AVTALE",
  DOWNLOAD_TERMS: "LAST NED VILKÅR",
} as {
  VIEW_DETAILS: string;
  DOWNLOAD_AGREEMENT: string;
  DOWNLOAD_TERMS: string;
};

const billingCycleMap = {
  Monthly: "MONTHLY",
  Yearly: "YEARLY",
  Fixed: "FIXED",
} as const;

export const useExtendedBillable = ({
  tender,
  serviceOffers,
  supplierId,
}: ExtendedBillableProps) => {
  // Composables
  const router = useRouter();
  const { viewBlobFile, viewFileInNewWindow } = useFileDownload();
  const { getAttachment } = useFileAttachmentApi();
  const { getFrameAgreement, getTenderConfirmationUri } = useTenderApi();
  const { fetchCmsContent } = useCms();
  const { getUserInfo } = useUsersApi();

  // State
  const state = reactive<State>({
    termsAndConditionsCms: null as TermsAndConditionsPageCms | null,
    frameAgreement: null,
    isLoadingFrameAgreement: false,
    offeredByUser: null as UserDTO | null,
    acceptedByUser: null as UserDTO | null,
  });

  // Computed Properties
  const currentOffer = computed(() => serviceOffers[0]);

  const offerName = computed(
    () =>
      currentOffer.value?.serviceOfferExtra?.nickname ??
      "Tilbud på tjenestetype",
  );

  const priceRequest = computed(() => tender.priceRequests?.[0]);

  const supplierRequest = computed(() =>
    priceRequest.value?.supplierRequests.find(
      (sr) => sr.supplierId === supplierId,
    ),
  );

  const hasFrameAgreement = computed(
    () => supplierRequest.value?.hasFrameAgreement ?? false,
  );

  const frameAgreementId = computed(
    () => supplierRequest.value?.frameAgreementId || null,
  );

  const findStateChange = (status: OfferStatus) =>
    serviceOffers[0]?.offerStateChanges?.find(
      (change) => change.stateTo === status,
    );

  const fetchUserInfo = async (userId: string): Promise<UserDTO | null> => {
    try {
      const response = await getUserInfo(userId);
      return response.data;
    } catch (error) {
      console.error(`Error fetching user info for ${userId}:`, error);
      return null;
    }
  };

  const fetchSignatureUsers = async () => {
    const offeredChange = findStateChange(OfferStatus.OFFERED);
    const acceptedChange = findStateChange(OfferStatus.ACCEPTED);

    if (offeredChange?.changedBy) {
      state.offeredByUser = await fetchUserInfo(offeredChange.changedBy);
    }

    if (acceptedChange?.changedBy) {
      state.acceptedByUser = await fetchUserInfo(acceptedChange.changedBy);
    }
  };

  const supplierName = computed(() => {
    const supplierRequest = tender.priceRequests
      ?.flatMap((record) => record.supplierRequests)
      .find((supplierRequest) =>
        supplierRequest.serviceOffers.includes(currentOffer.value?.id || 0),
      );
    return supplierRequest?.supplierName;
  });

  const signatureItems = computed(() => {
    const offeredChange = findStateChange(OfferStatus.OFFERED);
    const acceptedChange = findStateChange(OfferStatus.ACCEPTED);

    return [
      {
        id: 1,
        label: "Utstedt av",
        date: offeredChange?.createdOn
          ? formatDateToDotSeparated(offeredChange.createdOn)
          : "",
        name: state.offeredByUser?.displayName ?? "--",
        company: supplierName.value ?? "--",
      },
      {
        id: 2,
        label: "Aksept av",
        date: acceptedChange?.createdOn
          ? formatDateToDotSeparated(acceptedChange.createdOn)
          : "",
        name: state.acceptedByUser?.displayName ?? "--",
        company: tender.customerOrganization?.name ?? "--",
      },
    ];
  });

  // Helpers
  const getBillingCycleUnit = (cycle?: string): string => {
    if (!cycle) return "";
    const enumKey = billingCycleMap[cycle as keyof typeof billingCycleMap];
    return enumKey ? BillingCycleUnits[enumKey] : "";
  };

  const formatSupplierCost = (
    cost: number | undefined,
    billingCycle: string,
  ): string => {
    if (!cost) return "";
    return `${formatCurrency(cost)} ${billingCycle} ekskl. mva`;
  };

  // Price Computations
  const formattedSupplierCost = computed(() => {
    const billingCycle = getBillingCycleUnit(currentOffer.value?.billingCycle);
    return formatSupplierCost(currentOffer.value?.supplierCost, billingCycle);
  });

  const supplierCostSubtitle = computed(() => {
    const billingCycle = getBillingCycleUnit(currentOffer.value?.billingCycle);
    return `Gjennomsnittlig kostnad per ${billingCycle} ekskl. mva`;
  });

  // API Methods
  const fetchFrameAgreement = async (): Promise<void> => {
    if (
      !hasFrameAgreement.value ||
      !frameAgreementId.value ||
      !tender.customerOrganizationId
    ) {
      state.isLoadingFrameAgreement = false;
      return;
    }

    state.isLoadingFrameAgreement = true;
    try {
      const response = await getFrameAgreement({
        organizationId: tender.customerOrganizationId,
        frameAgreementId: frameAgreementId.value,
      });
      state.frameAgreement = response.data;
    } catch (err) {
      console.error("Error fetching frame agreement:", err);
      state.frameAgreement = null;
    } finally {
      state.isLoadingFrameAgreement = false;
    }
  };

  const fetchTermsAndConditions = async (): Promise<void> => {
    try {
      const response = await fetchCmsContent(
        ContentType.TERMS_AND_CONDITIONS_PAGE_CMS,
      );
      state.termsAndConditionsCms = response as TermsAndConditionsPageCms;
    } catch (error) {
      console.error("Error fetching terms and conditions:", error);
      state.termsAndConditionsCms = null;
    }
  };

  // Action Handlers
  const handleDownloadTermsClick = async (): Promise<void> => {
    try {
      if (hasFrameAgreement.value && state.frameAgreement) {
        await downloadFrameAgreementAttachment();
      } else {
        openTermsAndConditions();
      }
    } catch (error) {
      console.error("Error downloading terms:", error);
    }
  };

  const downloadFrameAgreementAttachment = async (): Promise<void> => {
    const attachment = state.frameAgreement?.attachments[0];
    if (!attachment) throw new Error("No frame agreement attachment found");

    const response = await getAttachment({
      attachmentId: attachment.attachmentId,
      tenderId: tender.tenderId,
      fileName: attachment.fileName,
    });

    await viewFileInNewWindow(response.data, router);
  };

  const openTermsAndConditions = (): void => {
    const filedoc = state.termsAndConditionsCms?.filedoc;
    console.log("filedoc", filedoc);

    if (!filedoc) throw new Error("Terms and conditions URL not found");
    const url = getFileUrl(filedoc, "seeAllTerms");
    console.log("url", url);
    if (!url) throw new Error("Terms and conditions URL not found");
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleSeeAgreementClick = async (): Promise<void> => {
    if (!currentOffer.value?.id) return;

    await viewBlobFile(async () => {
      const response = await getTenderConfirmationUri({
        tenderId: tender.tenderId,
        offerId: currentOffer.value?.id || 0,
        audience: "Supplier",
      });
      return response.data;
    }, router);
  };

  const viewDetails = (): void => {
    router.push({
      name: RouteNames.EDIT_OFFER,
      params: {
        tenderId: tender.tenderId,
        offerId: currentOffer.value?.id,
      },
    });
  };

  // Initialize
  const initialize = async (): Promise<void> => {
    await Promise.all([
      fetchTermsAndConditions(),
      fetchFrameAgreement(),
      fetchSignatureUsers(),
    ]);
  };

  // Action Buttons
  const actionButtons = computed(() => {
    const buttons = [
      {
        id: 1,
        label: BUTTON_LABELS.VIEW_DETAILS,
        action: viewDetails,
      },
      {
        id: 2,
        label: BUTTON_LABELS.DOWNLOAD_AGREEMENT,
        action: handleSeeAgreementClick,
      },
    ];

    if (hasFrameAgreement.value && state.frameAgreement?.attachments.length) {
      buttons.push({
        id: 3,
        label: BUTTON_LABELS.DOWNLOAD_TERMS,
        action: handleDownloadTermsClick,
      });
    }

    return buttons;
  });

  return {
    state,
    offerName,
    formattedSupplierCost,
    supplierCostSubtitle,
    hasFrameAgreement,
    initialize,
    viewDetails,
    handleSeeAgreementClick,
    handleDownloadTermsClick,
    actionButtons,
    signatureItems,
  };
};
