<template>
  <div>
    <div class="partners__title">Parter</div>
    <div class="partners__description">
      Under er alle partene i avtalen oppgitt.
    </div>
    <div class="partners__content">
      <div
        v-for="(partner, index) in partners"
        :key="index"
        class="partners__partner-wrapper"
      >
        <agreement-partner
          :title="partner.title"
          :partner-details="partner.details"
          :collapsible="collapsible"
          :expanded="collapsible ? expanded : true"
          @toggle-collapse="toggleCollapse"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AgreementPartner from "@/components/AgreementPartners/AgreementPartner.vue";
import addressService from "@/services/addressService";
import { mapGetters, mapState } from "vuex";

export default {
  components: { AgreementPartner },
  props: {
    tender: {
      type: Object,
      required: true,
    },
    supplierOrgNumber: {
      type: String,
      required: true,
    },
    collapsible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      expanded: false,
      isAddressesLoading: false,
      supplierAddresses: [],
    };
  },
  computed: {
    ...mapState(["cms"]),
    ...mapGetters("supplier", {
      supplier: "getSupplier",
    }),
    getSupplierAddress() {
      return this.supplierAddresses?.[0]?.address?.address || "--";
    },
    getSupplierCityAddress() {
      return (
        `${this.supplierAddresses?.[0]?.address?.postalCode} ${this.supplierAddresses?.[0]?.address?.city}` ||
        "--"
      );
    },
    partners() {
      const customerOrganization = this.tender.customerOrganization;
      const customerPropertyAddress =
        this.tender.priceRequests[0].customerPropertyDto;
      const getFormattedOrgNumber = (orgNumber) =>
        `Org. nr.: ${orgNumber || "--"}`;
      return [
        {
          title: "Kunde",
          details: [
            customerOrganization.name,
            customerPropertyAddress.address.address,
            customerPropertyAddress.address.city,
            getFormattedOrgNumber(customerOrganization.orgNumber),
          ],
        },
        {
          title: "Leverandør",
          details: [
            this.supplier.sysName,
            this.getSupplierAddress,
            this.getSupplierCityAddress,
            getFormattedOrgNumber(this.supplierOrgNumber || "--"),
          ],
        },
      ];
    },
  },
  created() {
    this.$store.dispatch("cms/loadFooterContent");
    this.fetchSupplierAddresses();
  },
  methods: {
    toggleCollapse() {
      this.expanded = !this.expanded;
    },
    async fetchSupplierAddresses() {
      const supplierId = this.supplier?.id;
      if (!supplierId) return;

      this.isAddressesLoading = true;

      try {
        const { data } = await addressService.getSupplierAddresses(supplierId);
        this.supplierAddresses = data || [];
      } catch (error) {
        console.error("Error fetching supplier addresses:", error);
        this.supplierAddresses = [];
      } finally {
        this.isAddressesLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.partners {
  &__title {
    font-weight: 600;
    font-size: $font-size-2xl;
  }

  &__description {
    margin-top: 0.75rem;
    font-weight: 400;
    font-size: $font-size-base;
  }

  &__content {
    margin-top: 1rem;
    display: flex;
    gap: 2rem;
  }

  &__partner-wrapper {
    width: 50%;
  }
}
</style>
