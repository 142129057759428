<template>
  <div class="agreement-view container">
    <div class="agreement-view__agreement-details-summary row">
      <div class="col-md-6 px-0 d-flex justify-content-start left-section">
        <extended-billable-price-summary
          :tender="tender"
          :supplier-id="supplierId"
          :service-offers="serviceOffers"
          :billable-price-summary="billablePriceSummary"
          :frequency-options="frequencyOptions"
          :is-loading="isLoading"
        />
        <div v-if="errorMessage" class="supplier-offer-list__error-message">
          <feedback-overlay
            :feedback-type="errorMessage.type"
            :message-title="errorMessage.messageTitle"
            :message-info="errorMessage.messageInfo"
            @close="clearError"
          />
        </div>
      </div>
      <div
        class="col-md-6 px-0 d-flex justify-content-end align-items-center right-section"
      >
        <invoice-recipient
          :external-tender-id="tender.externalTenderId"
          :external-supplier-portfolio-id="externalSupplierPortfolioId"
          :external-offer-id="externalOfferId"
        />
        <customer-contact-information
          :name="customerDetails?.displayName"
          :email="customerDetails?.email"
          :phone-number="customerDetails?.mobilePhone"
          :is-loading="isCustomerLoading"
        />
      </div>
    </div>
    <div class="agreement-view__supplier-offers-list row">
      <supplier-offers-wrapper
        :supplier-id="supplierId"
        :tender="tender"
        :show-new-offer-button="false"
        :frequency-options="frequencyOptions"
      />
    </div>
  </div>
</template>

<script>
import InvoiceRecipient from "@/components/InvoiceRecipient/InvoiceRecipient.vue";
import ExtendedBillablePriceSummary from "@/components/ExtendedBillablePriceSummary/ExtendedBillablePriceSummary.vue";
import CustomerContactInformation from "@/components/CustomerContactInformation/CustomerContactInformation.vue";
import {
  ActivityFrequency,
  OfferStatus,
  PriceType,
} from "@/custom-types/types";
import { FeedbackType, BillingCycle, JobType } from "@/custom-types/types";
import SupplierOffersWrapper from "@/components/SupplierOffersWrapper/SupplierOffersWrapper.vue";
import cmsService from "@/services/cmsService";
import {
  resolveFrequency,
  serviceActivityCms,
} from "@/utilities/activityUtilities";
import { formatCurrency } from "@/utilities/numberUtils";
import { updateBillablePriceSummaryDetails } from "@/utilities/billablePriceUtilities";
import tenderService from "@/services/tenderService";
import userService from "@/services/userService";

export default {
  name: "AgreementWrapper",
  components: {
    InvoiceRecipient,
    CustomerContactInformation,
    ExtendedBillablePriceSummary,
    SupplierOffersWrapper,
  },
  props: {
    tender: {
      type: Object,
      required: true,
    },
    supplierId: {
      type: Number,
      required: true,
    },
    frequencyOptions: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    serviceOfferId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      errorMessage: null,
      serviceActivitiesCms: null,
      title: "Faste kostnader (ekskl. mva)",
      currency: "kr",
      averagePricePerYearText: "Gjenomsnittlig kostnad per år",
      totalBillableText: "Fakturerbart",
      totalMonthlyText: "Fakturerbart per måned",
      singleCostText: "Enkeltkostnader",
      totalDrivingCostText: "Kjørekostnader",
      company: null,
      customer: null,
      isCustomerLoading: true,
      perMonthText: "per måned",
    };
  },
  computed: {
    customerDetails() {
      return this.customer;
    },

    priceRequest() {
      return this.tender.priceRequests[0];
    },

    serviceOffers() {
      if (!this.tender) return;

      const serviceTypePackagePortfolio =
        this.getSelectedServiceTypePackagePortfolio();

      return serviceTypePackagePortfolio?.serviceOffers;
    },

    externalSupplierPortfolioId() {
      if (!this.tender) return;

      const acceptedOffer = this.tender.supplierPortfolios.find(
        (supplierPortfolio) =>
          supplierPortfolio.serviceTypePackagePortfolio.some(
            (portfolio) =>
              portfolio.offerStatus === OfferStatus.Accepted ||
              portfolio.offerStatus === OfferStatus.Completed,
          ),
      );

      return acceptedOffer?.externalSupplierPortfolioId;
    },

    externalOfferId() {
      if (!this.tender) return;

      const serviceOfferId = this.getServiceExternalOfferId();

      return serviceOfferId;
    },

    getSingleCostText() {
      try {
        const { serviceActivitiesCms, singleCostText } = this;
        const activityCms = serviceActivityCms(
          serviceActivitiesCms,
          ActivityFrequency.Fixed,
        );

        return activityCms?.title ?? singleCostText;
      } catch (error) {
        console.warn("Error getting single cost text:");
        return this.singleCostText;
      }
    },

    billablePriceSummary() {
      if (!this.serviceOffers || this.serviceOffers.length === 0) {
        return;
      }

      const { priceRequests } = this.tender;

      const aggregatedPriceSummaries = this.extractAggregatedPrices(
        this.serviceOffers,
        priceRequests?.[0],
      );

      return this.buildBillablePriceSummary(aggregatedPriceSummaries);
    },
  },
  watch: {
    company: {
      async handler() {
        await this.resolveCustomer();
      },
      deep: true,
      immediate: true,
    },
  },

  async created() {
    this.isLoading = true;
    const ERROR_TITLE = "Feil ved lasting av data";

    try {
      this.serviceActivitiesCms = await cmsService.getServiceActivities();
      this.company = await this.resolveCustomerInvoiceRecipient();
    } catch (error) {
      console.error("Error loading data:", error);
      const errorMessage = error?.response?.data;
      this.errorMessage = {
        type: FeedbackType.Error,
        messageTitle: ERROR_TITLE,
        messageInfo: errorMessage ? JSON.stringify(errorMessage) : "",
      };
      setTimeout(() => this.clearError(), 4500);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    buildBillablePriceSummary(priceSummaries) {
      const details = priceSummaries.map(({ text, price }) => ({
        text,
        price: formatCurrency(price),
      }));

      return updateBillablePriceSummaryDetails(
        details,
        this.averagePricePerYearText,
        this.getSingleCostText,
        this.totalMonthlyText,
      );
    },

    getServiceExternalOfferId() {
      if (!this.serviceOffers || this.serviceOffers.length === 0) {
        return undefined;
      }

      return this.serviceOffers[0]?.externalOfferId;
    },

    getSelectedServiceTypePackagePortfolio() {
      if (!this.tender.supplierPortfolios) return;

      const serviceTypePackagePortfolios =
        this.tender.supplierPortfolios.flatMap(
          (supplierPortfolio) => supplierPortfolio.serviceTypePackagePortfolio,
        );

      return serviceTypePackagePortfolios.find(
        this.isServiceTypePackagePortfolioSelected,
      );
    },

    isServiceTypePackagePortfolioSelected(serviceTypePackagePortfolio) {
      return (
        serviceTypePackagePortfolio.offerStatus === OfferStatus.Accepted ||
        serviceTypePackagePortfolio.offerStatus === OfferStatus.Completed
      );
    },

    clearError() {
      this.errorMessage = null;
    },

    formatBillableText(
      billingCycle,
      frequencyLabel,
      isActivePeriod,
      activePeriodStart,
      activePeriodEnd,
    ) {
      const formattedText = this.totalBillableText.trim();

      const frequencyText = this.getResolvedFrequencyText(
        billingCycle,
        frequencyLabel,
      );
      const cmsData = this.getServiceActivityCmsData(
        this.serviceActivitiesCms,
        billingCycle,
      );
      const limitedPeriodText = this.getLimitedPeriodText(
        isActivePeriod,
        activePeriodStart,
        activePeriodEnd,
      );

      switch (billingCycle) {
        case ActivityFrequency.Yearly:
          return `${formattedText} ${frequencyText}`;

        case ActivityFrequency.Fixed:
          return cmsData;

        case ActivityFrequency.Monthly:
          return `${formattedText} ${this.perMonthText}${limitedPeriodText}`;

        default:
          return formattedText;
      }
    },

    getResolvedFrequencyText(billingCycle, frequencyLabel) {
      return frequencyLabel
        ? this.resolvedFrequency(billingCycle, frequencyLabel)
        : "";
    },

    getServiceActivityCmsData(serviceActivitiesCms, billingCycle) {
      return (
        serviceActivityCms(serviceActivitiesCms, billingCycle)?.title ||
        this.singleCostText
      );
    },

    getLimitedPeriodText(isActivePeriod, activePeriodStart, activePeriodEnd) {
      return isActivePeriod
        ? ` (${activePeriodStart?.substring(
            0,
            3,
          )} - ${activePeriodEnd?.substring(0, 3)})`
        : "";
    },

    resolvedFrequency(billingCycle, frequencyLabel) {
      const serviceActivityCmsData = serviceActivityCms(
        this.serviceActivitiesCms,
        billingCycle,
      );
      return resolveFrequency(
        serviceActivityCmsData,
        billingCycle,
        frequencyLabel,
      );
    },

    calculatePrice(summary) {
      const price = summary.frequencyValue * Math.round(summary.supplierCost);
      return price;
    },

    createPriceObj({
      billingCycle,
      supplierCost,
      frequencyValue,
      frequencyLabel,
      text,
      priceType,
      isActivePeriod,
      segmentGroupName = null,
    }) {
      const priceObj = {
        price: supplierCost,
        text,
        billingCycle,
        frequencyValue,
        supplierCost,
        frequencyLabel,
      };

      if (segmentGroupName) {
        priceObj.segmentGroupName = segmentGroupName;
      }

      if (priceType) {
        priceObj.priceType = priceType;
      }

      if (billingCycle === BillingCycle.Monthly) {
        priceObj.limitedPeriod = isActivePeriod;
      }

      return priceObj;
    },

    isFixedPriceType(billingCycle, priceType) {
      return (
        billingCycle === BillingCycle.Fixed &&
        priceType === PriceType.Aggregated
      );
    },

    isDrivingPriceType(priceType) {
      return priceType === PriceType.Driving;
    },

    isMonthlyOrYearlyAggregate(billingCycle, priceType) {
      return (
        [BillingCycle.Monthly, BillingCycle.Yearly].includes(billingCycle) &&
        priceType === PriceType.Aggregated
      );
    },

    extractPriceSummary(serviceOfferPrice, serviceOffer, priceRequest) {
      try {
        const {
          activePeriodStart,
          activePeriodEnd,
          priceSummary,
          priceType,
          segmentGroupPrices,
        } = serviceOfferPrice;
        const formattedText = this.totalBillableText;
        const summary = priceSummary[0];
        const { billingCycle, supplierCost, frequencyValue, frequencyLabel } =
          summary;
        const isActivePeriod = activePeriodEnd && activePeriodStart;
        let obj = {};
        const fixedPriceTypeArray = [];

        if (this.isFixedPriceType(billingCycle, priceType)) {
          segmentGroupPrices?.forEach((segmentGroupPrice) => {
            const { segmentGroupName, supplierCost } = segmentGroupPrice;
            obj = this.createPriceObj({
              billingCycle,
              supplierCost,
              frequencyValue,
              frequencyLabel,
              priceType,
              text:
                priceRequest?.jobType === JobType.Repeating
                  ? this.singleCostText
                  : `${formattedText} ${segmentGroupName}`,
            });
            if (obj.price) fixedPriceTypeArray.push(obj);
          });
          if (fixedPriceTypeArray.length > 0) {
            return fixedPriceTypeArray;
          }
        }

        if (this.isDrivingPriceType(priceType)) {
          obj = this.createPriceObj({
            billingCycle,
            supplierCost,
            frequencyValue,
            frequencyLabel,
            text: this.totalDrivingCostText,
            priceType,
            isActivePeriod,
          });
        }

        if (this.isMonthlyOrYearlyAggregate(billingCycle, priceType)) {
          obj = this.createPriceObj({
            billingCycle,
            supplierCost: supplierCost,
            frequencyValue,
            frequencyLabel,
            text: this.formatBillableText(
              billingCycle,
              frequencyLabel,
              isActivePeriod,
              activePeriodStart,
              activePeriodEnd,
            ),
            isActivePeriod,
          });
        }

        return obj.price ? obj : null;
      } catch (error) {
        console.error("Failed to extract price summary:", error);
        return null;
      }
    },

    extractAggregatedPrices(offers, priceRequest) {
      const VALID_BILLING_CYCLES = [
        BillingCycle.Yearly,
        BillingCycle.Fixed,
        BillingCycle.Monthly,
      ];

      const extractedSummaries = offers.flatMap((offer) =>
        offer.serviceOfferPrices
          .flatMap((price) => {
            if (
              ![PriceType.Aggregated, PriceType.Driving].includes(
                price.priceType,
              )
            ) {
              return [];
            }
            return this.extractPriceSummary(price, offer, priceRequest) || [];
          })
          .filter((summary) =>
            VALID_BILLING_CYCLES.includes(summary.billingCycle),
          ),
      );

      const totalSum = extractedSummaries.reduce(
        (acc, current) => {
          if (current.billingCycle !== BillingCycle.Fixed) {
            acc.price += this.calculatePrice(current);
          }
          return acc;
        },
        { price: 0, text: this.averagePricePerYearText },
      );

      return [totalSum, ...extractedSummaries];
    },

    async resolveCustomerInvoiceRecipient() {
      const tenderId = this.tender?.tenderId;
      const offerId = this.serviceOfferId;
      if (!tenderId || !offerId) {
        console.warn("Tender or offer ID is missing");
      }

      try {
        const result = await tenderService.getInvoiceRecipient(
          tenderId,
          offerId,
        );
        return result.data;
      } catch (e) {
        console.error("Error fetching company data:", e);
        return {};
      }
    },

    async resolveCustomer() {
      const email = this.company?.email;
      if (!email) return {};

      try {
        const result = await userService.getUserByEmail(email);
        this.customer = result.data;
      } catch (e) {
        console.error("Error fetching customer data:", e);
        return {};
      } finally {
        this.isCustomerLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.agreement-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 22rem;

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    color: $color-primary;
    margin: auto;
  }

  &__agreement-details-summary {
    display: flex;
    flex-direction: row;
    gap: 16px;
    background-color: #fff;
  }

  &__supplier-offers-list {
    width: 100%;
    margin-top: 32px;
  }

  .left-section {
    flex: 3;
    border-radius: 4px;
    min-width: 38rem;
    background-color: $color-primary-pink;
  }

  .right-section {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .invoice-recipient,
    .contact-information {
      border-radius: 4px;
      width: 100%;
      background-color: $color-primary-pink;
    }

    .invoice-recipient {
      flex: 1;
    }

    .contact-information {
      flex: 1;
    }
  }
}

.container {
  padding-right: 0;
  padding-left: 0;
  max-width: none;
}

.row {
  width: 100%;
}
</style>
