export enum LanguageCode {
  NO = "no",
  EN = "en",
}

export enum MessageDomain {
  CUSTOMER_SUPPLIER = "CustomerSupplier",
  SUPPLIER_CUSTOMER = "SupplierCustomer",
}

export enum TenderState {
  OFFERED = "Offered",
  ACCEPTED = "Accepted",
  REJECTED = "Rejected",
  PRICE_REQUESTED = "PriceRequested",
  INFORMATION_REQUESTED = "InformationRequested",
  COMPLETED = "Completed",
}

export enum RequestState {
  OFFERED = "Offered",
  ACCEPTED = "Accepted",
  REJECTED = "Rejected",
  PRICE_REQUESTED = "PriceRequested",
  INFORMATION_REQUESTED = "InformationRequested",
  COMPLETED = "Completed",
  LOST = "Lost",
  ARCHIVED = "Archived",
  UNANSWERED = "Unanswered",
  DRAFT = "Draft",
}

export enum OfferState {
  ACCEPTED = "Accepted",
  REJECTED = "Rejected",
  COMPLETED = "Completed",
  WITHDRAWN = "Withdrawn",
  DRAFT = "Draft",
  OFFERED = "Offered",
  LOST = "Lost",
}

export enum MessageType {
  PRICE_REQUEST = "PriceRequest",
  GENERAL_MESSAGE = "GeneralMessage",
  COMPLIMENT = "Compliment",
  COMPLAINT = "Complaint",
  PRICE_OFFER = "PriceOffer",
}

export enum JobType {
  SINGLE = "Enkeltjobb",
  REPEATING = "Repeterende",
}

export enum ActivityFrequency {
  FIXED = "Fixed",
  MONTHLY = "Monthly",
  YEARLY = "Yearly",
}

export enum BillingCycle {
  FIXED = "Fixed",
  MONTHLY = "Monthly",
  YEARLY = "Yearly",
}

export enum BillingType {
  FIXED = "Fixed",
  VARIABLE = "Variable",
}

export enum SupplierCreationSource {
  REGISTRATIONS = "Registrations",
  SUPPLIER = "Supplier",
}

export type LocalAttachment = {
  fileName: string;
  attachmentUri: string;
  downloadHandler: () => Promise<void>;
};

export const ReallyAdmin = {
  REALLY_ADMIN: "ReallyAdmin",
} as const;

export const OnceOrMorePerMonthFrequency = {
  ITEM: "Item",
  SQUARE_METERS: "SquareMeters",
  HOURS_PER_MONTH: "HoursPerMonth",
  SECTIONS: "Sections",
} as const;

export const LessThanOncePerMonthFrequency = {
  ITEM: "Item",
  SQUARE_METERS: "SquareMeters",
  HOURS: "Hours",
  SECTIONS: "Sections",
} as const;

export const SingleCostFrequency = {
  ITEM: "Item",
  SQUARE_METERS: "SquareMeters",
  HOURS: "Hours",
  SECTIONS: "Sections",
} as const;

export enum RequestType {
  SERVICE_WITHOUT_ROBOT = "ServiceWithoutRobot",
  ROBOT = "Robot",
}

export enum BillingCycleUnits {
  YEARLY = "kr/år",
  MONTHLY = "kr/mnd",
  FIXED = "kr",
}

export const Months = {
  JANUARY: 1,
  FEBRUARY: 2,
  MARCH: 3,
  APRIL: 4,
  MAY: 5,
  JUNE: 6,
  JULY: 7,
  AUGUST: 8,
  SEPTEMBER: 9,
  OCTOBER: 10,
  NOVEMBER: 11,
  DECEMBER: 12,
} as const;

export enum OfferStatus {
  DRAFT = "Draft",
  OFFERED = "Offered",
  WITHDRAWN = "Withdrawn",
  ACCEPTED = "Accepted",
  REJECTED = "Rejected",
  COMPLETED = "Completed",
  LOST = "Lost",
}

export enum Audience {
  SUPPLIER = "Supplier",
  CUSTOMER = "Customer",
}

export enum ServiceActivityCmsLabelType {
  FIXED = "fixed",
  MONTHLY = "monthly",
  YEARLY = "yearly",
}

export enum PriceType {
  AGGREGATED = "Aggregated",
  DRIVING = "Driving",
  INDEPENDENT = "Independent",
}

export interface UserDTO {
  companyName: string | null;
  displayName: string;
  email: string;
  mobilePhone: string | null;
  orgNumber: string;
  principalName: string;
  supplierId: number;
  userId: string;
  termsAndConditionsAccepted: boolean;
  newsletterAccepted: boolean;
  otherCommunicationAccepted: boolean;
}

export interface CreateUserDTO {
  name: string | null;
  displayName: string | null;
  email: string | null;
  phoneNumber: string;
  orgNumber: string;
  companyName: string | null;
  password: string;
  supplierId: number | null;
}

export interface CreatedSupplierDTO {
  margin: number;
  commission: number;
  email: string;
  logo: string;
  description: string;
  contactPerson: string;
  orgNumber: string;
  startBank: string;
  revenue: number;
  employees: number;
  reservationLower: number;
  reservationHigher: number;
  reservationKilometers: number;
  certifications: string[];
  sustainabilityGoals: string[];
  offices: string[];
  id: number;
  sysName: string;
  entityPath: string;
}

export interface User {
  userId: string;
  orgNumbers: string;
  phoneNumber?: string;
  email: string;
  name: string;
  termsAndConditionsAccepted: boolean;
  newsletterAccepted: boolean;
  otherCommunicationAccepted: boolean;
}

export interface EditUserDTO {
  userId: string;
  orgNumber?: string;
  phoneNumber?: string;
  name: string;
  termsAndConditionsAccepted: boolean;
  newsletterAccepted: boolean;
  otherCommunicationAccepted: boolean;
}

export interface SearchSuggestion {
  id: string | number;
  suggestionText: string;
  icon: string;
  disabled?: boolean;
}

export const TenderViewTabLabels = {
  priceRequest: "price-request",
  messages: "messages",
  offers: "offers",
  agreement: "agreement",
  documentation: "documentation",
  economy: "economy",
};

export interface PublicActorInfo {
  name: string;
  winningBid: {
    company: string;
    value: number;
  };
  otherBids: Array<{
    company: string;
    value: number;
  }>;
}
export interface Feedback {
  reasons: Reason[];
  comment: string;
}
export interface AcceptanceReason {
  id: number;
  reason: string;
}

export interface Reason {
  id: number;
  reason: string;
  text: string;
}

export interface InvoiceDTO {
  id: number;
  invoiceNumber: string;
  customerName: string;
  invoiceDate: string;
  paymentDueDate: string;
  taxInclusiveAmount: number;
  statusCode: number;
  restAmount: number;
  vatTotalsAmount: number;
  taxExclusiveAmount: number;
  emailAddress: string;
  comment: string | null;
  credited: boolean;
  lastPaymentDate: string | null;
  deliveryDate: string;
  ourReference: string | null;
  yourReference: string | null;
}
