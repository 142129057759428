<template>
  <div class="start-date">
    <div class="start-date__title">{{ title }}</div>
    <div class="start-date__description">
      <custom-block-content :blocks="description" />
    </div>
    <div class="start-date__date-picker">
      <vue-date-picker
        v-model="startDate"
        value-type="date"
        format="dd.MM.yyyy"
        locale="no"
        :popup-style="popupStyles"
        :disabled-date="disableDateInDatePicker"
        :append-to-body="false"
        :enable-time-picker="false"
      >
        <template #input-icon />
        <template #clear-icon>
          <img
            src="/images/calendar-dark.svg"
            class="input-slot-image"
            alt="Calendar"
          />
        </template>
      </vue-date-picker>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import dayjs from "dayjs";
import { cloneDeep } from "lodash";
import CustomBlockContent from "@/components/CustomBlockContent/CustomBlockContent.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

// Assuming props are passed as shown below, adjust if needed
const props = defineProps({
  pageCms: {
    type: Object,
    default: null,
  },
  offer: {
    type: Object,
    default: null,
  },
  readOnly: Boolean,
  minimumDaysToStartDate: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits(["new-offer"]);

// Reactive state
const startDate = computed({
  get: () => props.offer.wantedStartDate,
  set: (val) => {
    const offerCopy = cloneDeep(props.offer);
    offerCopy.wantedStartDate = val;
    emit("new-offer", offerCopy);
  },
});

const startDateCms = computed(() =>
  props.pageCms.pageSections.find(
    (section) => section.label === "startDateSection",
  ),
);

const title = computed(() => startDateCms.value?.title);
const description = computed(() => startDateCms.value?.content);

const popupStyles = computed(() => ({
  left: 0,
  top: "100%",
  zIndex: 999,
  visibility: props.readOnly ? "hidden" : "visible",
}));

const disableDateInDatePicker = (date) => {
  const minimumStartDate = dayjs().add(props.minimumDaysToStartDate, "day");
  return dayjs(date).isBefore(minimumStartDate, "day");
};
</script>

<style lang="scss" scoped>
.start-date {
  &__title {
    font-weight: 600;
    font-size: $font-size-base;
  }

  &__description {
    margin-top: 0.5rem;
    font-weight: 400;
    font-size: 14px;
  }

  &__date-picker {
    margin-top: 1rem;
    display: block;
    width: 100%;
    max-width: 11.75rem;
  }
}

.input-slot-image {
  height: 20px;
  width: auto;
  margin-right: 16px;
}

:deep(.dp__input) {
  padding-left: 1rem;
  padding-right: 2.5rem;
  border: 1px solid $color-black;
  border-radius: 4px;
  height: 2.75rem;
  font-weight: 500;
}
</style>
