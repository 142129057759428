<template>
  <div class="send-offer-form">
    <div class="send-offer-form__title">{{ title }}</div>
    <p class="send-offer-form__description">
      {{ description }}
    </p>
    <label class="send-offer-form__message-input-label">
      {{ messageInputCmsTitle }}
      <base-text-area
        v-model="formData.message"
        class="send-offer-form__message-input"
        rows="5"
        :placeholder="messageInputCmsPlaceholder"
      />
    </label>
    <div v-if="hasOtherOffers" class="send-offer-form__withdraw-offers">
      <div class="send-offer-form__withdraw-offers-label">
        {{ withdrawOffersInputCms.title }}
      </div>
      <div class="send-offer-form__withdraw-offers-options">
        <div v-for="option in withdrawOfferOptions" :key="option.value">
          <base-radio-input
            v-model="formData.withdrawOtherOffers"
            :label="option.label"
            :value="option.value"
          />
        </div>
      </div>
    </div>
    <div v-if="!shouldOfferBeSent" class="send-offer-form__error-message">
      <feedback-overlay
        :feedback-type="error.type"
        :message-title="error.messageTitle"
        :message-info="error.messageInfo"
        @close="clearError"
      />
    </div>
    <div class="send-offer-form__buttons">
      <tertiary-button :disabled="isSubmitting" @click="cancel">
        {{ cancelButtonText }}
      </tertiary-button>
      <primary-button
        :disabled="!shouldOfferBeSent || isSubmitting"
        :is-submitting="isSubmitting"
        @click="sendOffer"
      >
        {{ sendOfferButtonText }}
      </primary-button>
    </div>
    <div class="send-offer-form__terms">
      <div class="send-offer-form__terms-content">
        <span>{{ supplierTermsConditionsText }}</span>
        <span class="send-offer-form__terms-link" @click="handleDownloadTerms">
          {{ supplierTermsConditionsLinkText }}
        </span>
        <span>.</span>
      </div>
    </div>
  </div>
</template>

<script>
import TertiaryButton from "@/components/Buttons/TertiaryButton.vue";
import PrimaryButton from "@/components/Buttons/PrimaryButton.vue";
import BaseTextArea from "@/components/Base/BaseTextArea/BaseTextArea.vue";
import { Audience, FeedbackType, OfferStatus } from "@/custom-types/types";
import tenderService from "@/services/tenderService";
import FeedbackOverlay from "@/components/FeedbackOverlay/FeedbackOverlay.vue";
import BaseRadioInput from "@/components/Base/BaseRadioInput/BaseRadioInput.vue";
import { getCmsItem, getCmsItemText } from "@/utilities/cmsUtilities";
import { extractFixedActivitiesFromManualServiceOffer } from "@/utilities/tenderUtilities";
import dayjs from "dayjs";
import cmsService from "@/services/cmsService";
import { getFileUrl } from "@/utilities/cmsUtilities";

export default {
  components: {
    BaseRadioInput,
    FeedbackOverlay,
    BaseTextArea,
    PrimaryButton,
    TertiaryButton,
  },
  props: {
    tender: {
      type: Object,
      required: true,
    },
    serviceOffer: {
      type: Object,
      required: true,
    },
    supplierId: {
      type: Number,
      required: true,
    },
    sendOfferPageCms: {
      type: Object,
      required: true,
    },
  },
  emits: ["cancel", "offer-sent"],
  data() {
    return {
      formData: {
        message: "",
        withdrawOtherOffers: false,
      },
      error: null,
      isSubmitting: false,
      wantedStartDateInPastErrorMessage:
        "Ønsket startdato er i fortiden. Vennligst velg en fremtidig dato.",
      errorMessage:
        "Minimum data før sending: 1) Minst én fast kostnadslinje 2) Minst én beskrivelseslinje",
    };
  },
  computed: {
    sendOfferFormCms() {
      return getCmsItem(this.sendOfferPageCms.forms, "sendOfferForm");
    },
    errorsCms() {
      const errorsSection = getCmsItem(
        this.sendOfferFormCms.formSections,
        "errors",
      );
      return errorsSection?.otherText ?? [];
    },
    title() {
      return getCmsItemText(this.sendOfferFormCms?.otherText, "title");
    },
    description() {
      return getCmsItemText(this.sendOfferFormCms?.otherText, "description");
    },
    cancelButtonText() {
      return getCmsItemText(this.sendOfferFormCms?.buttons, "cancelButton");
    },
    sendOfferButtonText() {
      return getCmsItemText(this.sendOfferFormCms?.buttons, "sendOfferButton");
    },
    messageInputCmsTitle() {
      return this.messageInputCms ? this.messageInputCms.title : "";
    },
    messageInputCmsPlaceholder() {
      return this.messageInputCms ? this.messageInputCms.placeholder : "";
    },
    messageInputCms() {
      return getCmsItem(this.sendOfferFormCms?.inputs, "messageInput");
    },
    withdrawOffersInputCms() {
      return getCmsItem(this.sendOfferFormCms?.inputs, "withdrawOffersInput");
    },
    withdrawOfferOptions() {
      return [
        {
          label: getCmsItemText(
            this.withdrawOffersInputCms?.RadioOptions,
            "yesOption",
          ),
          value: true,
        },
        {
          label: getCmsItemText(
            this.withdrawOffersInputCms?.RadioOptions,
            "noOption",
          ),
          value: false,
        },
      ];
    },
    hasOtherOffers() {
      const supplierPortfolio = this.tender.supplierPortfolios.find(
        (supplierPortfolio) => supplierPortfolio.supplierId === this.supplierId,
      );
      const serviceOffers = supplierPortfolio.serviceTypePackagePortfolio
        ?.length
        ? supplierPortfolio.serviceTypePackagePortfolio[0].serviceOffers
        : [];
      return serviceOffers.some(
        (serviceOffer) =>
          serviceOffer.id !== this.serviceOffer.id &&
          serviceOffer.offerState === OfferStatus.Offered,
      );
    },

    fixedCostActivities() {
      return extractFixedActivitiesFromManualServiceOffer(
        this.serviceOffer,
        this.tender,
      );
    },

    hasDescriptions() {
      const descriptions = this.serviceOffer.serviceOfferExtra.descriptions;
      return descriptions && descriptions.length > 0;
    },

    shouldOfferBeSent() {
      return (
        this.serviceOffer.offerState === OfferStatus.Draft &&
        this.hasDescriptions &&
        this.fixedCostActivities.length > 0
      );
    },

    isMissingError() {
      const isMissingError =
        this.errorMessage !== undefined &&
        this.error?.messageTitle === this.errorMessage;
      return isMissingError;
    },

    isWantedStartDateInThePast() {
      const wantedStartDate =
        this.serviceOffer.serviceOfferExtra?.wantedStartDate;
      if (!wantedStartDate) {
        return true;
      }
      return dayjs(wantedStartDate).isBefore(dayjs(), "day");
    },

    supplierTermsConditionsText() {
      return getCmsItemText(
        this.sendOfferFormCms?.otherText,
        "supplierTermsConditions_text",
      );
    },

    supplierTermsConditionsLinkText() {
      return getCmsItemText(
        this.sendOfferFormCms?.otherText,
        "supplierTermsConditions_link",
      );
    },
  },

  watch: {
    fixedCostActivities() {
      this.updateErrorBasedOnConditions();
    },
  },

  created() {
    this.updateErrorBasedOnConditions();
    this.fetchTermsAndConditionsCms();
  },

  methods: {
    updateErrorBasedOnConditions() {
      if (!this.shouldOfferBeSent) {
        this.error = this.generateMinimumDataErrorMessage();
      } else if (this.isWantedStartDateInThePast) {
        this.error = this.generateWantedStartDateErrorMessage();
      } else {
        this.clearError();
      }
    },

    generateMinimumDataErrorMessage() {
      return {
        type: FeedbackType.Error,
        messageTitle: this.errorMessage,
      };
    },

    generateWantedStartDateErrorMessage() {
      return {
        type: FeedbackType.Error,
        messageTitle: this.wantedStartDateInPastErrorMessage,
      };
    },

    cancel() {
      this.$emit("cancel");
    },

    async sendOffer() {
      if (this.isSubmitting) {
        return;
      }
      this.isSubmitting = true;
      try {
        const sendOfferDto = {
          supplierId: this.supplierId,
          audience: Audience.Supplier,
          state: OfferStatus.Offered,
          userId: this.$store.state.api.user.userId,
          message: this.formData.message,
          withdrawOtherOffers: this.formData.withdrawOtherOffers,
        };
        await tenderService.sendOffer(
          this.tender.tenderId,
          this.serviceOffer.id,
          sendOfferDto,
        );
        const tenderResponse = await tenderService.getTender(
          this.supplierId,
          this.tender.tenderId,
        );
        this.$emit("offer-sent", {
          tender: tenderResponse.data,
          message: sendOfferDto.message,
        });
      } catch (error) {
        const errorMessage = error?.response?.data;
        const matchingErrorCms = this.errorsCms.find(
          (cms) => cms.label === errorMessage,
        );
        this.error = {
          type: FeedbackType.Error,
          messageTitle: matchingErrorCms
            ? matchingErrorCms.title
            : errorMessage,
        };
      }
      this.isSubmitting = false;
    },
    clearError() {
      if (this.error?.messageTitle === this.errorMessage) {
        return;
      }
      this.error = null;
    },
    async fetchTermsAndConditionsCms() {
      const response = await cmsService.getTermsAndConditionsCms();
      if (response && response.length) {
        this.termsAndConditionsCms = response[0];
      }
    },
    handleDownloadTerms() {
      const url = getFileUrl(this.sendOfferPageCms?.filedoc, "supplierTerms");
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.send-offer-form {
  &__title {
    font-size: $font-size-2xl;
    font-weight: 600;
  }

  &__description {
    margin-top: 1rem;
    font-size: $font-size-base;
  }

  &__message-input-label {
    width: 100%;
    font-size: $font-size-sm;
    font-weight: 600;
    margin: 0;
  }

  &__message-input {
    margin-top: 0.5rem;
    display: block;
    width: 100%;
    resize: none;
    border-radius: 4px;
    padding: 0.75rem 1rem;
  }

  &__withdraw-offers {
    margin-top: 1rem;
  }

  &__withdraw-offers-label {
    font-size: $font-size-sm;
    font-weight: 600;
  }

  &__withdraw-offers-options {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__error-message {
    margin-top: 1.5rem;
  }

  &__buttons {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 1rem;
  }

  &__terms {
    margin-top: 1rem;
    padding: 1rem;
    background: #f4f4f4;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 0.625rem;
  }

  &__terms-content {
    flex: 1;
    font-size: $font-size-base;
    color: black;
    word-wrap: break-word;
  }

  &__terms-link {
    text-decoration: underline;
    cursor: pointer;
    display: inline;
    margin-left: 4px;

    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
